import TopNavBar from '@/js/layouts/topbar/TopNavBar';
import { Icons } from '@/utils/Icons';
import React, { useEffect } from 'react';
import userStore from '@/js/modules/users/store/v2/UserStore';
import { PageRoutes } from '@/utils/PageRoutes';
import useUserStore from '@/js/modules/users/store/useUserStore';
import blokPublicStore from '@/js/modules/bloks/store/BlokPublicStore.ts';
import subscriptionStore from '@/js/modules/subscriptions/store/SubscriptionStore.ts';
import conversationStore from '@/js/modules/chats/store/ConversationStore/ConversationStore';
import { AccountTypes } from '@/utils/Enums';
import { useQuery } from '@tanstack/react-query';
import notificationStore from '@/js/modules/notifications/store/NotificationStore';
import localStorage from '@/js/helpers/localStorage';

export default function RoleBasedNavbar() {
	blokPublicStore.initializeStore();
	conversationStore.initializeStore();
	subscriptionStore.initializeStore();

	const [menuItems, setMenuItems] = React.useState([
		{ to: PageRoutes.DiscoverBloksPage, name: 'Bloks', translation: 'TopBar.Discover' },
		{ to: PageRoutes.SponsorshipsPage, name: 'Sponsorships', translation: 'TopBar.Sponsorships' },
	]);
	const [dropdownItems, setDropdownItems] = React.useState([] as any[]);

	const { user, me } = useUserStore();

	useEffect(() => {
		// Component mounted. Calling me()
		me();
	}, [me]);

	const handleSetSubscriptionIndicator = () => {
		const subsItem = dropdownItems.find((item) => item?.type === 'subscription');

		if (subsItem) {
			subsItem.itemClasses = subscriptionStore.isPremiumPlan()
				? '!bg-purple-50 hover:!bg-purple-50'
				: subscriptionStore.isProPlan()
				? '!bg-violet-50 hover:!bg-violet-50'
				: 'bg-pink-50 hover:!bg-pink-50';
		}
	};

	const getBaseDropdownItems = () => [
		{
			to: PageRoutes.JoinedBloks,
			name: 'Joined Bloks',
			translation: 'TopBar.JoinedBloks',
			icon: Icons.Blok,
		},
		{
			to: PageRoutes.BlokCreate,
			name: 'Create Blok',
			translation: 'TopBar.CreateBlok',
			icon: Icons.PlusV2,
			type: 'createBlok',
		},
		{
			to: PageRoutes.CompanyCreate,
			name: 'Create Company',
			translation: 'TopBar.CreateCompany',
			icon: Icons.PlusV2,
			type: 'createCompany',
		},
		{
			to: PageRoutes.MemberPaymentHistory,
			name: 'Purchase History',
			translation: 'TopBar.CompanyPaymentHistory',
			icon: Icons.Payment,
		},
	];

	const getDynamicDropdownItems = (bloks, companies) => {
		const baseItems = getBaseDropdownItems();

		// Check if the user has bloks
		const bloksDropdown = bloks.length > 0 && {
			to: PageRoutes.MyBloksPage,
			name: 'My Bloks',
			translation: 'UserFeedMyBloks.Title',
			icon: Icons.Bloks,
			children: bloks.map((blok) => ({
				to: PageRoutes.BlokPublicPage.replace(':slug', blok.slug).replace(':tab?', ''),
				name: blok.name,
				translation: null,
			})),
		};

		// Check if the user has companies
		const companiesDropdown = companies.length > 0 && {
			name: 'My Companies',
			translation: 'UserFeedMyCompanies.Title',
			icon: Icons.Companies,
			children: companies.map((company) => ({
				to: PageRoutes.CompanyPublicPage.replace(':slug', company.slug),
				name: company.name,
				translation: null,
			})),
		};

		const dynamicItems = baseItems.map((item) => {
			if (item.type === 'createBlok' && bloks.length > 0) {
				return bloksDropdown;
			}
			if (item.type === 'createCompany' && companies.length > 0) {
				return companiesDropdown;
			}
			return item;
		});

		return dynamicItems;
	};

	useQuery({
		queryKey: ['navItems', user.isOnboardingCompleted, user.isDetailsCompleted],
		queryFn: () => {
			if (user.isOnboardingCompleted && user.isDetailsCompleted) {
				userStore.fetchAccountType().then((accountTypes) => {
					if (!accountTypes) {
						return;
					}

					const blokOwners = accountTypes
						.filter((account) => account?.accountType === AccountTypes.BlokOwner)
						.map((account) => ({
							name: account.name ?? '',
							slug: account.slug ?? '',
						}));

					const companyOwners = accountTypes
						.filter((account) => account.accountType === AccountTypes.CompanyOwner)
						.map((account) => ({
							name: account.name ?? '',
							slug: account.slug ?? '',
						}));

					setMenuItems(menuItems);
					const dropdownItems = getDynamicDropdownItems(blokOwners, companyOwners);
					setDropdownItems(dropdownItems);
					if (userStore.isBlokOwnerAccountType()) {
						handleSetSubscriptionIndicator();
					}
				});
			}
			return true;
		},
		enabled: localStorage.has('user'),
	});

	useQuery({
		queryKey: ['subscriptionStatus', user.accountType, blokPublicStore.state.info?.user?.id],
		queryFn: async () => {
			// Check if the user is a current blok owner
			if (blokPublicStore.state.info?.user?.id === user.id) {
				const activePlan = await subscriptionStore.fetchPlanSubscriptionStatus();
				if (!activePlan) {
					await subscriptionStore.CreateFreePlan();
				}
			} else if (blokPublicStore.state.info?.user?.id) {
				await subscriptionStore.fetchPlanSubscriptionStatusById(
					blokPublicStore.state.info?.user?.id
				);
			}
			return true;
		},
		enabled: localStorage.has('user'),
	});

	const { data: hasMessages } = useQuery({
		queryKey: ['navbarMessages', user.id],
		queryFn: async () => {
			const result = await conversationStore.fetchForNotifications();
			return result;
		},
		enabled: localStorage.has('user'),
	});

	const { data: hasNotifications } = useQuery({
		queryKey: ['navbarNotifications', user.id],
		queryFn: async () => {
			const result = await notificationStore.hasNotifications();
			return result;
		},
		enabled: localStorage.has('user'),
	});

	return (
		<TopNavBar hasNotifications={hasNotifications} isSearchable={false} hasMessages={hasMessages}>
			<TopNavBar.MenuSection menuItems={menuItems} />
			<TopNavBar.ActionSection />
			<TopNavBar.AuthorSection
				dropdownItems={dropdownItems}
				hasMessages={hasMessages}
				hasNotifications={hasNotifications}
			/>
		</TopNavBar>
	);
}

export enum PageRoutes {
	Index = '/',
	SignIn = '/sign-in',
	SignUp = '/sign-up',
	WelcomePage = '/welcome',
	SignUpMailActivation = '/sign-up/activation',
	DidNotReceiveActivationMail = '/sign-up/activation/resend',
	MailActivationTimeout = '/sign-up/activation/timeout',
	MailActivationFailure = '/sign-up/activation/failure',
	Details = '/sign-up/details',
	ResetPassword = '/sign-in/reset-password',
	ForgotPassword = '/sign-in/forgot-password',
	Onboarding = '/onboarding',
	CompanyCreate = '/dashboard/company/create',
	BlokCreate = '/dashboard/bloks/create',
	CompanyEdit = '/dashboard/company/edit/:id',
	BlokOwner = '/onboarding/blok-owner',
	Dashboard = '/dashboard',
	CreateJobSelectionPage = '/dashboard/jobs/create',
	JobSummaryStep = '/dashboard/jobs/:postType/create/summary',
	EditJobSummaryStep = '/dashboard/jobs/:postType/edit/summary/:id',
	JobDetailsStep = '/dashboard/jobs/:postType/edit/details/:id',
	JobsPreviewStep = '/dashboard/jobs/:postType/preview/:id',
	JobsPublishStep = '/dashboard/jobs/:postType/publish/:id',
	JobApplicationStep = '/dashboard/jobs/:postType/application/:id',
	JobVerificationStep = '/dashboard/jobs/:postType/verification/:id',
	EditJobSelectBlokStep = '/dashboard/jobs/:postType/select/:id',
	JobCheckoutStep = '/dashboard/jobs/:postType/checkout/:id',
	TalentCreate = '/dashboard/talent/create',
	TalentUpdate = '/dashboard/talent/edit/:id',
	TalentUpdateDetails = '/dashboard/member/details/edit/:id',
	TalentProfilePage = '/dashboard/member/:id',
	JobPostPage = '/jobs/post/:id',
	BlokOwnerCreate = '/dashboard/bloks/owner',
	BlokOwnerEdit = '/dashboard/bloks/:id/owner',
	BlokEdit = '/dashboard/bloks/:id/edit',
	BlokVerify = '/dashboard/bloks/:id/verify',
	OldBlokPublicPage = '/dashboard/bloks/:id/:tab?',
	BlokPublicPage = '/bloks/:slug/:tab?',
	MyBlokPage = '/dashboard/my/blok',
	MyBloksPage = '/dashboard/my/bloks',
	MyProfilePage = '/dashboard/my/profile',
	UserFeedPage = '/dashboard/feed',
	Verification = '/verification',
	NotFound = '/not-found',
	Forbidden = '/forbidden',
	DashboardRewardJobs = '/dashboard/jobs/rewards',
	DashboardBlokJobs = '/dashboard/jobs/bloks',
	LandingForCompanies = '/companies',
	LandingForCommunities = '/',
	LandingPageForTalents = '/members',
	RequestDemo = '/request-demo',
	Pricing = '/pricing',
	FAQ = '/faq',
	Contact = '/contact',
	Contracts = '/contracts',
	DWSPlus = '/blog',
	JobPostApplications = '/dashboard/jobs/:id/applications',
	BlokJobPostRewards = '/dashboard/bloks/jobs/rewards',
	JoinedBloks = '/dashboard/joined-bloks',
	DiscoverBloksPage = '/dashboard/discover',
	SponsorshipsPage = '/dashboard/sponsorships',
	Admin = '/admin',
	AdminBlokListPage = '/admin/blok-list/:state',
	AdminPerkListPage = '/admin/perks-list',
	AdminJobPostListPage = '/admin/job-post-list/:state',
	AdminEditTransaltionsPage = '/admin/translations/',
	AdminJobPostBloksListPage = '/admin/job-post-bloks-list/:state',
	AdminUserListPage = '/admin/users/:accountType/:state',
	AdminBlogPostsListPage = '/admin/blog-posts/:state',
	AdminBlogPostCreatePage = '/admin/blog-post-create',
	AdminBlogPostEditPage = '/admin/blog-post-edit/:id',
	AdminServiceListPage = '/admin/services',
	AdminServiceCreatePage = '/admin/services/create',
	AdminServiceEditPage = '/admin/services/:id/edit',
	AdminPaymentListPage = '/admin/payments/by/:id',
	AdminBlokTalentsListPage = '/admin/blok-talents/:state',
	AdminBlokCompaniesListPage = '/admin/blok-companies/:state',
	AdminCompaniesListPage = '/admin/companies',
	AdminCreatePerkPage = '/admin/perks/create',
	AdminUpdatePerkPage = '/admin/perks/update/:blokId/:perkId',
	AdminJobPostCandidatesListPage = '/admin/job-post-candidates',
	Contract = '/contracts/:name',
	AboutUs = '/about-us',
	Payment = '/dashboard/services/:id/:ownerType/:ownerId/payment',
	PaymentSuccess = '/dashboard/payment/success',
	PaymentFailed = '/dashboard/payment/failed',
	MyCompanyPage = '/dashboard/my/company',
	CompanyPublicPage = '/companies/:slug',
	LinkedInCallback = '/linkedin',
	CreateBlokPostPage = '/dashboard/bloks/:id/create/post',
	CreatePostPage = '/dashboard/posts/:ownerType/:ownerId/create',
	EditPostPage = '/dashboard/posts/:ownerType/:ownerId/edit/:id',
	PostDetailPage = '/posts/:ownerType/:ownerId/:id',
	PackageDetailPage = '/bloks/:blokId/services/:serviceId',
	PerkDetailPage = '/bloks/:blokId/perks/:perkId',
	CompleteRegistration = '/complete-registeration',
	CompleteCompanyRegistration = '/complete-company-registeration',
	MemberPaymentHistory = '/dashboard/payments',
	BlokServiceCreate = '/dashboard/my/blok/services/:id?',
	BlokAdvertCreate = '/dashboard/my/blok/advert/:id?',
	ManageBlokPage = '/dashboard/:slug/manage-blok/:tab?',
	MessagesPage = '/dashboard/messages',
	BuySubscriptionPlanPage = '/dashboard/settings/subscription-plans/:type/:planLabel',
	SubscriptionPaymentSuccessPage = '/dashboard/settings/subscription/payment/success',
	EventDetailPage = '/events/:slug',
	EventCheckInPage = '/dashboard/event/check-in/:slug',
	EventViewTicketPage = '/dashboard/event/ticket/:eventId/:ticketId',
	ForumDetailPage = '/:blokSlug/forum/:forumSlug',
}

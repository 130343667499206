import { Outlet } from 'react-router-dom';
import RoleBasedNavbar from '@/js/components/RoleBasedNavbar/RoleBasedNavbar';
import blokPublicStore from '@/js/modules/bloks/store/BlokPublicStore';
import ContractUpdateModal from '@/js/modules/auth/components/ContractUpdateModal';
import React from 'react';
import userStore from '@/js/modules/users/store/v2/UserStore';

interface IDashboardLayout {}

export default function DashboardLayout(props: IDashboardLayout) {
	blokPublicStore.initializeStore(blokPublicStore.state);
	userStore.initializeStore();

	const [showContractModal, setShowContractModal] = React.useState(false);

	React.useEffect(() => {
		if (userStore.state.showContractModal) {
			setShowContractModal(true);
		}
	}, [userStore.state.showContractModal]);


	return (
		<>
			<RoleBasedNavbar />
			<main
				className={`h-[calc(100vh-120px)] max-w-screen-2xl mx-auto mt-0 print:mt-0 border-y border-shade-stroke`}
			>
				<Outlet />
				{showContractModal && <ContractUpdateModal onClose={() => setShowContractModal(false)} />}
			</main>
		</>
	);
}

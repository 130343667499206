import { useToggle } from '@/js/hooks/useToggle/useToggle';
import TopBarMobileMenu from './mobile/TopBarMobileMenu';
import TopBarMobileMenuButton from './mobile/TopBarMobileMenuButton';
import React, { useEffect } from 'react';
import TopBarSearch from './search/TopBarSearch';
import { Icons } from '@/utils/Icons';
import { useWindowSize } from '@/js/hooks/useWindowSize/useWindowSize';
import { ScreenSizes } from '@/utils/ScreenSizes';
import TopNavBarMenu from './TopNavBarMenu';
import { useGetChildByDisplayName } from '@/js/hooks/useGetChildByDisplayName/useGetChildByDisplayName';
import Author from './author/Author';
import DWSLogo from '@/js/components/DWS/DWSLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import TopNavBarChangeLanguage from './TopNavBarChangeLanguage';
import clsx from 'clsx';
import NotificationBar from '@/js/components/NotificationBar/NotificationBar';
import MessageIcon from '@/assets/icons/MessageIcon';
import { PageRoutes } from '@/utils/PageRoutes';
import BellIconV3 from '@/assets/icons/BellIconV3';
import NotificationModal from './notifications/NotificationModal';

export const useOnClickOutside = (
	ref: React.RefObject<HTMLElement>,
	handler: (event: MouseEvent | TouchEvent) => void,
	exceptions: React.RefObject<HTMLElement>[] = []
) => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			if (
				!ref.current ||
				ref.current.contains(event.target as Node) ||
				exceptions.some((exc) => exc.current?.contains(event.target as Node))
			) {
				return;
			}
			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, exceptions]);
};

interface ITopBar extends React.PropsWithChildren {
	children?: React.ReactNode;
	isSearchable?: boolean;
	hasNotifications?: number;
	hasMessages?: number;
	useLong?: boolean;
	useOnlyLogo?: boolean;
	hasTranslation?: boolean;
	forceRedirect?: boolean;
}

const TopNavBar = (props: ITopBar) => {
	const { children, isSearchable = true, forceRedirect = false } = props;

	const ref = React.useRef<HTMLDivElement>(null);
	const [moreActionsRef, setMoreActionsRef] =
		React.useState<React.RefObject<HTMLButtonElement> | null>(null);

	const location = useLocation();
	const { width } = useWindowSize();
	const navigate = useNavigate();

	const [isMobileMenuOpen, toggleIsMobileMenuOpen, setIsMobileMenuOpen] = useToggle(false);

	const { ActionSection, AuthorSection, MenuSection } = useGetChildByDisplayName(children, [
		'MenuSection',
		'ActionSection',
		'AuthorSection',
	]);

	useOnClickOutside(
		ref,
		() => {
			setIsMobileMenuOpen(false);
			setIsNotificationsOpen(false);
		},
		moreActionsRef ? [moreActionsRef] : []
	);

	const [isNotificationsOpen, toggleIsNotificationsOpen, setIsNotificationsOpen] = useToggle(false);

	const handleToggleMobileMenu = () => {
		setIsNotificationsOpen(false);
		toggleIsMobileMenuOpen();
	};

	const handleToggleNotifications = () => {
		setIsMobileMenuOpen(false);
		toggleIsNotificationsOpen();
	};

	React.useEffect(() => {
		if (width >= ScreenSizes.Large) {
			setIsMobileMenuOpen(false);
			setIsNotificationsOpen(false);
		}
	}, [width, setIsMobileMenuOpen, setIsNotificationsOpen]);

	React.useEffect(() => {
		setIsMobileMenuOpen(false);
		setIsNotificationsOpen(false);
		window.scrollTo(0, 0);
	}, [location.pathname, setIsMobileMenuOpen, setIsNotificationsOpen]);

	return (
		<>
			<nav
				ref={ref}
				className={clsx(`bg-white border-b border-gray-100 z-50 print:hidden px-4`, {
					'sticky top-0 z-20': location.pathname.includes('post'),
					'max-w-screen-2xl mx-auto': !location.pathname.includes('onboarding'),
				})}
			>
				<div className={'max-w-full mx-auto'}>
					<div className={'flex items-center justify-between h-24'}>
						<div className={'flex items-center w-full gap-0 lg:gap-6 flex-1'}>
							<div className={'flex items-center flex-shrink-0'}>
								<DWSLogo forceRedirect={forceRedirect} useLong={props.useLong || false} />
							</div>
							{isSearchable && <TopBarSearch />}
						</div>
						<div className={'flex h-full'}>
							<div className={'hidden lg:flex h-full items-center'}>
								{MenuSection}
								{ActionSection}
							</div>
							{props.hasTranslation && <TopNavBarChangeLanguage />}
							{!props.useOnlyLogo && (
								<>
									<div className="flex items-center lg:hidden">
										<button onClick={() => navigate(PageRoutes.MessagesPage)} type={'button'}>
											<MessageIcon hasMessages={props.hasMessages} />
										</button>
										<button onClick={handleToggleNotifications} type={'button'}>
											<BellIconV3 hasNotifications={props.hasNotifications} />
										</button>
									</div>
									<TopBarMobileMenuButton
										isMobileMenuOpen={isMobileMenuOpen}
										toggleMobileMenu={handleToggleMobileMenu}
									/>
								</>
							)}
							<div className={'hidden lg:flex md:flex-shrink-0 md:items-center'}>
								{AuthorSection}
							</div>
						</div>
					</div>
				</div>
				{isMobileMenuOpen && (
					<TopBarMobileMenu
						actionButton={ActionSection?.props?.children}
						menuItems={MenuSection?.props?.menuItems || []}
						dropdownItems={AuthorSection?.props?.dropdownItems || []}
						isMobileMenuOpen={isMobileMenuOpen}
						closeMenu={() => setIsMobileMenuOpen(false)}
					/>
				)}
				<div className={'absolute right-0 xs:right-10 top-20 z-20'}>
					{isNotificationsOpen && (
						<NotificationModal
							isDropdownOpen={isNotificationsOpen}
							setMoreActionsRef={setMoreActionsRef}
						/>
					)}
				</div>
			</nav>
			<NotificationBar />
		</>
	);
};

interface IMenuSection extends React.PropsWithChildren {
	menuItems: {
		to: string;
		name: string;
		translation: string;
		openInNewTab?: boolean;
		forceRedirect?: boolean;
		element?: React.ReactNode | JSX.Element;
	}[];
}

const MenuSection = (props: IMenuSection) => {
	return <TopNavBarMenu items={props.menuItems} />;
};

interface IActionSection extends React.PropsWithChildren {}

const ActionSection = (props: IActionSection) => {
	return <div className={'ml-6 h-full'}>{props.children}</div>;
};

interface IUserSection {
	hasMessages?: number;
	hasNotifications?: number;
	dropdownItems?: {
		to: string;
		name: string;
		translation: string;
		icon: Icons;
		type?: string;
		children?: {
			to: string;
			name: string;
			translation: string;
		}[];
	}[];
}

const AuthorSection = (props: IUserSection) => {
	const { dropdownItems = [] } = props;
	return (
		<Author
			dropdownItems={dropdownItems}
			hasMessages={props.hasMessages}
			hasNotifications={props.hasNotifications}
		/>
	);
};

MenuSection.displayName = 'MenuSection';
ActionSection.displayName = 'ActionSection';
AuthorSection.displayName = 'AuthorSection';

TopNavBar.MenuSection = MenuSection;
TopNavBar.ActionSection = ActionSection;
TopNavBar.AuthorSection = AuthorSection;

export default TopNavBar;

import BaseService from "@/js/core/BaseService";
import { BlokEndpoints } from '@/js/modules/bloks/services/BlokEndpoints';
import { RowStates } from '@/js/core/enums/RowStates';

export default class BlokTabService extends BaseService {
	async fetchTalents(id: string, state: RowStates, page: number) {
		return this.api().get(`${BlokEndpoints.FetchBlokTalents.replace(':id', id)}?state=${state}&page=${page.toString()}`);
	}

	async fetchImportedTalents(id: string, state: RowStates, page: number) {
		return this.api().get(`${BlokEndpoints.FetchBlokTalents.replace(':id', id)}?state=${state}&page=${page.toString()}&imported=true`);
	}

	async fetchCompanies(id: string, state: RowStates) {
		return this.api().get(`${BlokEndpoints.FetchBlokCompanies.replace(':id', id)}?state=${state}`);
	}

	async fetchCompaniesForBlokOwner(blokId: string, query: string) {
		return await this.api().get(BlokEndpoints.FetchBlokCompaniesForBlokOwner.replace(':id', blokId) + query);
	}

	async fetchImportedCompanies(id: string, state: RowStates) {
		return this.api().get(`${BlokEndpoints.FetchBlokCompanies.replace(':id', id)}?state=${state}&imported=true`);
	}

	async fetchJobPosts(id: string, state?: RowStates) {
		if (state) {
			return this.api().get(`${BlokEndpoints.FetchBlokJobPosts.replace(':id', id)}?state=${state}`);
		}

		return this.api().get(BlokEndpoints.FetchBlokJobPosts.replace(':id', id));
	}

	async updateBlokJobPostState(id: string, jobPostId: string, state: RowStates) {
		return this.api().put(BlokEndpoints.UpdateBlokJobPostState.replace(':id', id).replace(':jobPostId', jobPostId), { state });
	}

	async updateBlokTalentState(id: string, talentId: string, state: RowStates) {
		return this.api().put(BlokEndpoints.UpdateBlokTalentState.replace(':id', id).replace(':talentId', talentId), { state });
	}

	async updateBlokTalentsState(id: string, talentList: string[], state: RowStates) {
		return this.api().post(BlokEndpoints.UpdateBlokTalentsState.replace(':id', id).replace(':state', state), talentList);
	}

	async updateBlokCompanyState(id: string, companyId: string, state: RowStates) {
		return this.api().put(BlokEndpoints.UpdateBlokCompanyState.replace(':id', id).replace(':companyId', companyId), { state });
	}

	async updateBlokCompaniesState(id: string, companyList: string[], state: RowStates) {
		return this.api().post(BlokEndpoints.UpdateBlokCompaniesState.replace(':id', id).replace(':state', state), companyList);
	}

	async deleteBlokTalent(id: string, talentId: string) {
		return this.api().delete(BlokEndpoints.UpdateBlokTalentState.replace(':id', id).replace(':talentId', talentId));
	}

	async deleteBlokCompany(id: string, companyId: string) {
		return this.api().delete(BlokEndpoints.UpdateBlokCompanyState.replace(':id', id).replace(':companyId', companyId));
	}

  async fetchTalentCount(id: string) {
		return this.api().get(`${BlokEndpoints.BlokTalentCount.replace(':id', id)}`);
	}

  async fetchCompanyCount(id: string) {
		return this.api().get(`${BlokEndpoints.BlokCompanyCount.replace(':id', id)}`);
	}

	async fetchFeaturedTalents(id: string) {
		return this.api().get(`${BlokEndpoints.FetchBlokTalentsForNonMembers.replace(':id', id)}`);
	}
}

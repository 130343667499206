import { Dispatch, SetStateAction, useEffect, RefObject } from "react";

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Dispatch<SetStateAction<boolean>>,
  isException?: (target: HTMLElement) => boolean
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const target = event.target as HTMLElement;

      if (
        !ref.current ||
        ref.current.contains(target) ||
        (isException && isException(target))
      ) {
        return;
      }

      handler(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, isException]);
};

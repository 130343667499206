import { PageRoutes } from '@/utils/PageRoutes';

export const ONBOARDING_ROUTES = [
  PageRoutes.Details,
  PageRoutes.Onboarding,
  PageRoutes.CompanyCreate,
  PageRoutes.BlokOwner
]

export default ONBOARDING_ROUTES;

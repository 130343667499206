import FormLogo from '@/js/modules/onboarding/components/company/FormLogo';
import { AccountTypes, LogoSizes } from '@/utils/Enums';
import { Account } from '@/utils/Types';
import { Icons } from '@/utils/Icons';
import Icon from '../Icon/Icon';
import React from 'react';
import useUserStore from '@/js/modules/users/store/useUserStore';
import forumStore from '@/js/modules/bloks/components/forum/store/ForumStore';
import commentStore from '@/js/modules/comments/store/CommentStore';
import blokPublicStore from '@/js/modules/bloks/store/BlokPublicStore';
import SwitchModal from './SwitchModal';
import userStore from '@/js/modules/users/store/v2/UserStore';

interface ISwitchUserIcon {
	onMessagesPage?: boolean;
	onJoinedBloksPage?: boolean;
	children?: (selectedUser: Account | null) => React.ReactNode;
	authorizedUserIds?: String[];
	onUserChange?: (user: Account) => void;
	initialSelectedUser?: Account;
}

const SwitchUserIcon = ({
	onMessagesPage = false,
	onJoinedBloksPage = false,
	children,
	authorizedUserIds,
	onUserChange,
	initialSelectedUser,
}: ISwitchUserIcon) => {
	commentStore.initializeStore();
	const accountInfo = userStore.state.accountInfo;
	const myBloks = accountInfo
		.filter((account) => account?.accountType === AccountTypes.BlokOwner)
		.map((account) => ({
			id: account.id ?? '',
			name: account.name ?? '',
			slug: account.slug ?? '',
			avatarUrl: account.avatarUrl ?? '',
			accountType: AccountTypes.BlokOwner,
		}));

	const myCompanies = accountInfo
		.filter((account) => account.accountType === AccountTypes.CompanyOwner)
		.map((account) => ({
			id: account.id ?? '',
			name: account.name ?? '',
			slug: account.slug ?? '',
			avatarUrl: account.avatarUrl ?? '',
			accountType: AccountTypes.CompanyOwner,
		}));

	const me = {
		id: useUserStore.getState().user.id,
		avatarUrl: useUserStore.getState().user.avatarUrl,
		name: `${useUserStore.getState().user.firstName} ${useUserStore.getState().user.lastName}`,
		accountType: AccountTypes.Talent,
	};

	const [switchModalOpen, setSwitchModalOpen] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState<Account>(initialSelectedUser || me);

	const myAccounts: Account[] = [me, ...(onJoinedBloksPage ? [] : myBloks), ...(myCompanies || [])];

	const filteredAccounts =
		authorizedUserIds && authorizedUserIds.length > 0
			? [
					...myAccounts.filter((account) => authorizedUserIds.includes(account.id)),
					...(commentStore.state.isOwner || blokPublicStore.isOwner() ? myBloks : []),
			  ]
			: myAccounts;

	const handleOpenSwitchModal = () => {
		if (filteredAccounts.length > 1) {
			setSwitchModalOpen(true);
			return;
		}
	};

	const handleUserChange = (user: Account) => {
		setSelectedUser(user);
		if (onUserChange) {
			onUserChange(user);
		}
	};

	return (
		<>
			<div
				role="button"
				className="flex items-center justify-center"
				onClick={handleOpenSwitchModal}
			>
				<FormLogo
					variant={forumStore.getLogoVariant(selectedUser.accountType || AccountTypes.Talent)}
					inputName={'userLogo'}
					logoUrl={selectedUser.avatarUrl}
					size={LogoSizes.XXS2}
					classes="cursor-pointer pointer-events-none"
				/>
				<Icon name={Icons.ChevronDown} />
			</div>

			{children && children(selectedUser)}

			{switchModalOpen && (
				<SwitchModal
					open={switchModalOpen}
					onClose={() => setSwitchModalOpen(false)}
					myAccounts={filteredAccounts}
					selectedUser={selectedUser}
					setSelectedUser={handleUserChange}
					onMessagesPage={onMessagesPage}
					onJoinedBloksPage={onJoinedBloksPage}
				/>
			)}
		</>
	);
};
export default SwitchUserIcon;

import BaseService from "@/js/core/BaseService";
import { ForumEndpoints } from "./ForumEndpoints";

export default class ForumService extends BaseService {

	async create(payload) {
		return await this.api().post(ForumEndpoints.Create, payload);
	}

	async update(id, payload) {
		return await this.api().put(ForumEndpoints.Update.replace(':id', id), payload);
	}

	async fetchOneBy(forumSlug: string) {
		return await this.api().get(ForumEndpoints.FetchOneBy.replace(':forumSlug', forumSlug));
	}

	async fetchInfoBy(forumSlug: string) {
		return await this.api().get(ForumEndpoints.FetchInfoBy.replace(':forumSlug', forumSlug));
	}

	async fetchAll(blokId: string, query: string) {
		return await this.api().get(ForumEndpoints.FetchAllByOwnerId.replace(':blokId', blokId) + query);
	}

	async delete(id) {
		return await this.api().delete(ForumEndpoints.Delete.replace(':id', id));
	}

}

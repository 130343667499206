import BaseStore from '@/js/core/BaseStore';
import { IConversation, IConversationStore } from './ConversationTypes';
import ConversationService from '../../services/ConversationService/ConversationService';
import { ConversationFilterTypes } from '../../components/ConversationFilter';
import slugify from '@/js/helpers/slugify';
import { MessageSenderTypes } from '@/utils/Enums';
import { PageRoutes } from '@/utils/PageRoutes';

const conversationStore: IConversationStore = {
  conversations: [],
  conversation: null,
  counts: {
    all: 0,
    read: 0,
    unread: 0,
    archived: 0,
  },
};

class ConversationStore extends BaseStore<IConversationStore, ConversationService> {
  initializeStore() {
    return this.useStore();
  }

  async updateConversationLastMessageReadDate(threadId: string) {
    try {
      const result = await this.service.updateConversationLastMessageReadDate(threadId);

      const lastMessageReadDate = result.data.result.lastMessageReadDate;

      this.state.conversation.lastMessageReadDate = lastMessageReadDate;

      return lastMessageReadDate;
    } catch (error) {
      console.log(error);
    }
  }

  async updateArchivedDate(threadId: string) {
    try {
      const result = await this.service.updateArchivedDate(threadId);

      const newArchivedDate = result.data.result.archivedDate;

      this.state.conversation.archivedDate = newArchivedDate;

      this.setCounts();

      return newArchivedDate;
    } catch (error) {
      console.log(error);
    }
  }

  async fetchConversationsByUser(filter: ConversationFilterTypes, senderAccountType: string) {
    try {
      this.state.isLoading = true;

      const conversations = await this.service.fetchConversationsByUser(filter, senderAccountType);

      this.state.conversations = conversations?.data?.result;

      this.setCounts();

      return conversations;
    } catch (error) {
      console.log(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  async fetchExistingConversationBetweenTwoUsers(receiverId: string, receiverType: string, senderAccountType: string) {
    const conversation = await this.service.fetchExistingConversationBetweenTwoUsers(receiverId, receiverType, senderAccountType);

    return conversation.data.result;
  }

  async fetchForNotifications() {
    const result = await this.service.fetchForNotifications();

    return result.data.result;
  }

  setConversationById(threadId: string) {
    const conversation = this.state?.conversations?.find((conversation: IConversation) => {
      return conversation.threadId === threadId;
    });

    this.state.conversation = conversation;
  }

  setCounts() {
    const conversations: IConversation[] = this.state.conversations;

    this.state.counts = {
      all: conversations?.filter((conv: IConversation) => !conv.archivedDate).length,
      read: conversations?.filter((conv: IConversation) => conv.lastMessageReadDate && !conv.archivedDate,).length,
      unread: conversations?.filter((conv: IConversation) => !conv.lastMessageReadDate && !conv.archivedDate,).length,
      archived: conversations?.filter((conv: IConversation) => conv.archivedDate).length,
    };
  }

  searchInConversations(conversation: IConversation, searchQuery: string) {
    return (
      conversation.threadId.includes(slugify(searchQuery)) ||
      slugify(conversation.senderName).includes(slugify(searchQuery)) ||
      slugify(conversation.lastMessage).includes(slugify(searchQuery))
    );
  }

  handleGetSenderRedirectUrl(senderType: MessageSenderTypes, blokSlug: string, companySlug: string, talentId: string) {
    switch (senderType) {
      case MessageSenderTypes.Blok:
        return PageRoutes.BlokPublicPage.replace(':slug', blokSlug).replace(':tab', '');

      case MessageSenderTypes.Company:
        return PageRoutes.CompanyPublicPage.replace(':slug', companySlug);

      case MessageSenderTypes.Talent:
        return PageRoutes.TalentProfilePage.replace(':id', talentId);

      default:
        throw new Error(`Unsupported sender type: ${senderType}`);
    }
  }

  filterConversationsBySearch(data: IConversation[], searchQuery: string) {
    return data.filter(c => this.searchInConversations(c, searchQuery));
  }

  filterConversations(filter: ConversationFilterTypes): IConversation[] {
    const conversations: IConversation[] = this.state.conversations;

    switch (filter) {
      case ConversationFilterTypes.All:
        return conversations.filter(conv => !conv.archivedDate);
      case ConversationFilterTypes.Archived:
        return conversations.filter(conv => conv.archivedDate);
      case ConversationFilterTypes.Unread:
        return conversations.filter(conv => !conv.lastMessageReadDate && !conv.archivedDate);
      case ConversationFilterTypes.Read:
        return conversations.filter(conv => conv.lastMessageReadDate && !conv.archivedDate);
      default:
        return conversations;
    }
  }
}

export default new ConversationStore(conversationStore, ConversationService);

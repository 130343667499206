import BaseStore from "@/js/core/BaseStore";
import educationDegreeStore from "../../educationDegrees/store/EducationDegreeStore";
import experienceStore from "../../experience/store/ExperienceStore";
import jobTypeStore from "../../jobTypes/store/JobTypeStore";
import languageStore from "../../languages/store/LanguageStore";
import locationStore from "../../locations/store/LocationStore";
import locationTypeStore from "../../locationTypes/store/LocationTypeStore";
import positionStore from "../../positions/store/PositionStore";
import skillStore from "../../skills/store/SkillStore";
import { selectMenuOptions } from "../components/TalentEditFormWorkPreferences";
import TalentService from "../services/TalentService";
import { ITalentStore, LookingForJobTypes } from "./TalentTypes";
import { errorHandler } from "@/js/helpers/errorHandler";
import skillExperienceStore from "../../skillExperiences/store/SkillExperienceStore";
import socialAccountStore from '@/js/modules/socialMedias/store/SocialAccountStore';
import userStore from "@/js/modules/users/store/v2/UserStore.ts";

const talentStore = {
	form: {
		firstName: '',
		lastName: '',
		avatarUrl: '',
		bio: '',
		isGsmNumberVisible: false,
		locationId: '',
		email: '',
		linkedInUrl: '',
		lastWorkedCompany: '',
		experienceId: '',
		educationDegreeId: '',
		lastEducationName: '',
		lastAcademicMajor: '',
		positionId: '',
		minimumSalary: '',
		lookingForJobType: '',
		talentSkillIds: [],
		talentHighlights: [],
		talentLanguageIds: [],
		talentLocationTypeIds: [],
		talentJobTypeIds: [],
		talentPositionIds: [],
		socialAccounts: [],
		isSalaryVisible: false,
		hasAgreement: false,
		isEmailVisible: false,
		isGsmVisible: false,
		isCvVisible: false,
		cv: [],
		isStudent: false,
		title: '',
		isEmployed: false,
		gsmNumber: '', // this is a user table field
		interests: []
	},
	isEditMode: false,
};

class TalentStore extends BaseStore<ITalentStore, TalentService> {
	initializeStore(state?) {
		return this.useStore(state ? state : undefined);
	}

	locationTypes() {
		const locationTypes = locationTypeStore.state.locationTypes;
		return this.optionsMapper(locationTypes);
	}

	jobTypes() {
		const jobTypes = jobTypeStore.state.jobTypes;
		return this.optionsMapper(jobTypes);
	}

	positions() {
		const positions = positionStore.state.positions;
		return this.optionsMapper(positions);
	}

	languages() {
		const languages = languageStore.state.languages;
		return this.optionsMapper(languages);
	}

	skills() {
		const skills = skillStore.state.skills;
		return this.optionsMapper(skills);
	}

	skillExperiences() {
		const skillExperiences = skillExperienceStore.state.skillExperiences;
		return this.optionsMapper(skillExperiences);
	}

	educationDegrees() {
		const educationDegrees = educationDegreeStore.state.educationDegrees;
		return this.optionsMapper(educationDegrees);
	}

	experiences() {
		const experiences = experienceStore.state.experiences;
		return this.optionsMapper(experiences);
	}

	locations() {
		const locations = locationStore.state.locations;

		let locationsWithCountryName = locations.map((item: any) => {
			if (item.name === item.countryName) {
				return { id: item.id, name: item.name };
			}

			return { id: item.id, name: `${item.name}, ${item.countryName}` };
		});

		locationsWithCountryName = locationsWithCountryName.sort(function (a, b) {
			return a.name.localeCompare(b.name, 'tr', { sensitivity: 'base' });
		});

		return this.optionsMapper(locationsWithCountryName);
	}

	lookingForJobType() {
		return this.state.form.lookingForJobType;
	}

	async fetchForTalentOnboarding() {
		await Promise.all([
			educationDegreeStore.fetchEducationDegrees(),
			locationStore.fetchLocations(),
			experienceStore.fetchExperiences(),
			skillStore.fetchSkills(),
			languageStore.fetchLanguages(),
			positionStore.fetchPositions(),
			skillExperienceStore.fetchSkillExperiences(),
			positionStore.fetchPositions(),
			jobTypeStore.fetchJobTypes(),
			locationTypeStore.fetchLocationTypes()
		]);
	}


	async fetchForTalentSearch() {
		await positionStore.fetchPositions()
		await locationStore.fetchLocations()
	}

	async createForEvent(slug: string) {
		try {
			this.state.isSaving = true;

			const user = await userStore.fetchMe();

			const payload = {
				slug,
				linkedInUrl: this.state.form.linkedInUrl,
				isEmployed: this.state.form.isEmployed,
				title: this.state.form.title,
				lastWorkedCompany: this.state.form.lastWorkedCompany,
				hasAgreement: this.state.form.hasAgreement,
				gsmNumber: this.state.form.gsmNumber,
				userId: user.id
			}

			const result = await this.service.createForEvent(payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async fetchForTalentGeneralInfo(id: string) {
		this.resetErrors();
		this.state.isLoading = true;
		try {
			await Promise.all([
				educationDegreeStore.fetchEducationDegrees(),
				locationStore.fetchLocations(),
				experienceStore.fetchExperiences(),
				skillStore.fetchSkills(),
				languageStore.fetchLanguages(),
				positionStore.fetchPositions(),
				skillExperienceStore.fetchSkillExperiences(),
				socialAccountStore.fetchSocialAccounts(),
			]);

			if (id) {
				this.state.isEditMode = true;

				const response = await this.service.fetchTalent(id);
				this.setFormData(response?.data?.result || {});
			} else {
				const user = await userStore.fetchMe();
				this.state.form.avatarUrl = user.avatarUrl
				const response = await this.service.fetchTalentByAuth();

				return response?.data?.result || null;
			}
		} catch (error) {
			if (id) {
				this.goToNotFound()
			}
		} finally {
			this.state.isLoading = false;
		}
	}

	setFormData(data) {
		this.state.form = {
			...data,
			firstName: data.user.firstName,
			lastName: data.user.lastName,
			avatarUrl: data.user.avatarUrl,
			bio: data.user.bio,
			isGsmNumberVisible: data.user.isGsmNumberVisible,
			cv: data.cv || [],
			socialAccounts: data.socialAccounts || [],
			experienceId: data.experience?.id,
			positionId: data.position,
			locationId: data.location?.id,
			educationDegreeId: data.educationDegree?.id,
			talentLanguageIds: data.talentLanguages,
			talentSkillIds: data.talentSkills,
			talentPositionIds: data.talentPositions,
			talentJobTypeIds: data.talentJobTypes,
			talentLocationTypeIds: data.talentLocationTypes,
			talentHighlights: data.talentHighlights,
			hasAgreement: data?.hasAgreement,
			isEmailVisible: data?.isEmailVisible,
			isCvVisible: data?.isCvVisible,
		}
	}

	async fetchForTalentEducation(id: string) {
		this.resetErrors();
		this.state.isLoading = true;
		try {
			await Promise.all([
				educationDegreeStore.fetchEducationDegrees(),
				experienceStore.fetchExperiences(),
				skillStore.fetchSkills(),
				languageStore.fetchLanguages(),
				positionStore.fetchPositions(),
				skillExperienceStore.fetchSkillExperiences(),
			]);

			if (id) {
				this.state.isEditMode = true;

				const response = await this.service.fetchTalent(id);
				this.setFormData(response?.data?.result || {});
			} else {
				const user = await userStore.fetchMe();
				this.state.form.avatarUrl = user.avatarUrl
				const response = await this.service.fetchTalentByAuth();

				return response?.data?.result || null;
			}
		} catch (error) {
			if (id) {
				this.goToNotFound()
			}
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchForTalentWorkPreferences(id) {
		this.resetErrors();
		this.state.isLoading = true;
		try {

			const response = await this.service.fetchTalent(id);
			this.setFormData(response.data.result);

			await Promise.all([
				positionStore.fetchPositions(),
				jobTypeStore.fetchJobTypes(),
				locationTypeStore.fetchLocationTypes()
			]);
		} finally {
			this.state.isLoading = false;
		}
	}

	optionsMapper(options) {
		return options.map(option => {
			return { label: option.name, value: option.id };
		});
	}

	getLookingForJobType() {
		return selectMenuOptions.find(item => {
			return item.value === this.state.form.lookingForJobType;
		}) || selectMenuOptions[2];
	}

	async createTalent(payload) {
		try {
			this.state.isSaving = true;

			const result = await this.service.createTalent(payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async createTalentAccount(payload) {
		try {
			this.state.isSaving = true;

			const result = await this.service.createTalentAccount(payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async verifyAndCreateTalentAccount(payload) {
		try {
			this.state.isSaving = true;

			const result = await this.service.verifyAndCreateTalentAccount(payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateTalent(id, payload) {
		try {
			this.state.isSaving = true;

			const response = await this.service.updateTalent(id, this.getPayload(payload));
			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateWorkPreferences(id, payload) {
		try {
			this.state.isSaving = true;

			const response = await this.service.updateWorkPreferences(id, this.getWorkPreferencesPayload(payload));

			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateImportedTalentData(blokId: string) {
		try {
			this.state.isSaving = true;

			const response = await this.service.updateImportedTalentData({ 'blokId' : blokId });

			return response;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	getCreatePayload(payload) {
		delete payload.firstName
		delete payload.lastName
		delete payload.email
		delete payload.avatarUrl
		delete payload.bio
		delete payload.isGsmNumberVisible
		delete payload.cv
		delete payload.talentLanguageIds
		delete payload.talentPositionIds

		payload.talentSkillIds?.forEach(skill => {
			if (skill.isNew) {
				skillStore.addSkill({ ...skill, id: skill.value });
			}
		});

		return {
			...payload,
			talentSkillIds: payload.talentSkillIds
				.map(skill => ({
					id: skill.value || '',
					isNew: skill.isNew,
					skillExperience: skill.skillExperience,
				}))
				.filter(skill => skill.id !== ''),
		}
	}

	setCreatePayload(payload) {
		this.state.form = {
			...this.state.form,
			...payload,
			talentSkillIds: payload.talentSkillIds
				.map(skill => ({
					value: skill.id,
					isNew: skill.isNew,
					skillExperience: skill.skillExperience,
				}))
				.filter(skill => skill.value !== ''),
		}
	}

	getPayload(payload) {
		return {
			...payload,
			talentLanguageIds: payload.talentLanguageIds.map(langauge => langauge.value || ''),
			talentSkillIds: payload.talentSkillIds
				.map(skill => ({
					id: skill.value || '',
					isNew: skill.isNew,
					skillExperience: skill.skillExperience,
				}))
				.filter(skill => skill.id !== ''),
		}
	}

	getWorkPreferencesPayload(payload) {
		return {
			talentPositionIds: payload.talentPositionIds.map(position => position.value || ''),
			talentLocationTypeIds: payload.talentLocationTypeIds.map(locationType => locationType.value || locationType),
			talentJobTypeIds: payload.talentJobTypeIds.map(jobType => jobType.value || jobType),
			minimumSalary: payload.minimumSalary,
			isSalaryVisible: payload.isSalaryVisible,
			lookingForJobType: payload.lookingForJobType || LookingForJobTypes.Close,
			hasAgreement: payload.hasAgreement,
		}
	}

	onUploadCv(url, name) {
		this.state.form.cv.push({ url, name });
	}

	onRemoveCv() {
		this.state.form.cv = [];
	}

	providers() {
		return socialAccountStore.state.socialAccounts;
	}

	public resetSocialMediaErrors() {
		const errors = {};
		Object.keys(this.state.errors).map((key) => {
			if (!key.includes('url')) {
				errors[key] = this.state.errors[key];
			}
		});

		this.state.errors = errors;
	}
}

export default new TalentStore(talentStore, TalentService) as TalentStore;

import TopNavBarMenuItem from "./TopNavBarMenuItem"

interface ITopBarNavigation extends React.PropsWithChildren {
    items: {
        to: string,
        name: string,
        translation: string
        openInNewTab?: boolean
        forceRedirect?: boolean
        element?: React.ReactNode | JSX.Element
    }[]
}


const TopNavBarMenu = (props: ITopBarNavigation) => {
    const activeClassName = `inline-flex h-12 items-center justify-center px-1 pt-1 text-body-semibold-b5 text-primary-700 text-center w-[138px]`
    const inActiveClassName = "inline-flex h-12 items-center justify-center px-1 pt-1 text-primary-500 text-body-semibold-b5 hover:text-[18px] w-[138px] text-center"

    const getClassNames = (isActive: boolean) => {
        if (isActive) return activeClassName
        else return inActiveClassName
    }

    return (
        <div className={"items-center hidden md:flex"}>
            {props.items.map(item => (
                <TopNavBarMenuItem
                    item={item}
                    key={item.name}
                    getClassNames={getClassNames}
                />
            ))}
        </div>
    )
}

export default TopNavBarMenu
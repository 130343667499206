export enum JobEndpoints {
  CreateJobSummary = '/job-posts',
  FetchJobPost = '/job-posts',
  UpdateJobPost = '/job-posts',
  FetchJobPostsByCompany = '/job-posts/by/company',
  FetchRewardJobPosts = '/job-posts/:id/rewards',
  FetchBlokJobPosts = '/job-posts/:id/bloks',
  FetchApprovedBlokJobPosts = '/job-posts/:id/bloks/approved',
  FetchJobPostForPublic = '/job-posts/:id/public',
  CreateJobPostTalent = '/job-posts/talents',
  SuggestionTalent = '/job-posts/suggestions',
  FetchTalents = '/job-posts/:id/talents/:blokId',
  FetchJobPostApplications = '/job-posts/:id/applications',
  fetchTalentsByReferredId = '/job-posts/talents/by/referrer',
  FetchAllRewardsJobPosts = '/job-posts/rewards/all',
  UpdateTalentApplicationState = '/job-posts/:id/applications',
  UpdateBloksByJobPostId = '/job-posts/bloks/:id',
  CreatePayment = '/job-posts/publish/bloks/payment',
  UnpublishJobPost = '/job-posts/:id/unpublish',
  SuggestTalentWithLinkedIn = '/job-posts/suggestions/linkedin',
  FetchLinkedInTalentsByReferredId = '/job-posts/linkedInTalents/by/referrer',
  UpdateJobPostTalentReferralStatus = '/job-posts/talents/:id/referral-status',
  GetJobPostState = '/job-posts/:id/state',
}
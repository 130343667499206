import ModalV2 from '@/js/components/ModalV2/ModalV2';
import FormLogo from '@/js/modules/onboarding/components/company/FormLogo';
import { AccountTypes, LogoSizes } from '@/utils/Enums';
import { Account } from '@/utils/Types';
import { __ } from '@/utils/Trans';
import forumStore from '@/js/modules/bloks/components/forum/store/ForumStore';
import { InputType } from '../Input/InputType';
import Button from '../Buttons/Button';
import React from 'react';

interface ISwitchModal {
	open: boolean;
	onClose: () => void;
	myAccounts: Account[];
	selectedUser: Account;
	setSelectedUser(user: Account): void;
	onMessagesPage?: boolean;
	onJoinedBloksPage?: boolean;
}

export default function SwitchModal({ onMessagesPage = false, onJoinedBloksPage = false, ...props }: ISwitchModal) {
	const [currentlySelectedUser, setCurrentlySelectedUser] = React.useState<Account>(
		props.selectedUser
	);

	const handleSaveButtonClick = () => {
		props.setSelectedUser(currentlySelectedUser);
		props.onClose();
	};

	return (
		<ModalV2 open={props.open} onClose={props.onClose}>
			<div className="flex flex-col gap-4 items-start -mt-[55px]">
				<p className="text-[22px] font-bold text-shade-black text-left">
					{onMessagesPage? __('SwitchUserModal.InboxTitle') : onJoinedBloksPage? __('SwitchUserModal.JoinedBloksTitle') : __('SwitchUserModal.Title')}
				</p>
				{props.myAccounts.map((account) => (
					<div key={account?.id} className="flex h-[78px] items-center justify-between w-full">
						<div className="flex items-center gap-3">
							<FormLogo
								variant={forumStore.getLogoVariant(account?.accountType || AccountTypes.Talent)}
								inputName={'userLogo'}
								logoUrl={account.avatarUrl}
								size={LogoSizes.SM2}
							/>
							<p className="text-base font-semibold text-shade-black line-clamp-2">{account?.name}</p>
						</div>
						<input
							name={'accountId'}
							type={InputType.Radio}
							onChange={() => setCurrentlySelectedUser(account)}
							id={account?.id}
							value={account?.id}
							className={`text-primary-500 w-6 h-6 focus:ring-transparent mx-2.5`}
							checked={currentlySelectedUser?.id === account?.id}
						/>
					</div>
				))}
				<div className="flex justify-end items-center w-full">
					<Button name={'saveButton'} attrs={{ onClick: handleSaveButtonClick }}>
						{__('CreateCompanyPage.Save')}
					</Button>
				</div>
			</div>
		</ModalV2>
	);
}

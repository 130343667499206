import { Icons } from '@/utils/Icons';
import LogOutButton from '../author/LogOutButton';
import TopBarDropdownMenuItem from '../dropdown/TopNavBarDropdownMenuItem';
import TopNavBarChangeLanguage from '../TopNavBarChangeLanguage';
import React from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

interface ITopBarMobileMenuDropdown extends React.PropsWithChildren {
	items: {
		to: string;
		name: string;
		translation: string;
		icon: Icons;
		children?: {
			to: string;
			name: string;
			translation: string;
		}[];
	}[];
}

const TopBarMobileMenuDropdown = (props: ITopBarMobileMenuDropdown) => {
	const [openSubmenu, setOpenSubmenu] = React.useState<string | null>(null);

	const toggleSubmenu = (itemName: string) => {
		setOpenSubmenu(openSubmenu === itemName ? null : itemName);
	};

	return (
		<div className={'mt-3 space-y-1'}>
			{props.items.map((item) => (
				<div key={item.name}>
					<TopBarDropdownMenuItem
						key={item.name}
						item={item}
						classes={
							'flex gap-3 hover:bg-gray-50 flex-row-reverse px-4 py-2 justify-end text-sm text-shade-black hover:text-primary-500 sm:px-6'
						}
						isSubmenuOpen={openSubmenu === item.name}
						toggleSubmenu={() => toggleSubmenu(item.name)}
					/>

					{/* Render submenu here in the parent */}
					{item.children && openSubmenu === item.name && (
						<Transition
							appear
							show={openSubmenu === item.name}
							as={React.Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div className="space-y-2">
								<div className="w-full">
									{item.children.map((child) => (
										<Link
											key={child.name}
											to={child.to}
											className="block pl-[50px] px-4 py-2 text-sm text-shade-black hover:bg-gray-50 hover:text-primary-500 line-clamp-2"
										>
											{child.name}
										</Link>
									))}
								</div>
							</div>
						</Transition>
					)}
				</div>
			))}
			<div className={'flex items-center w-full sm:px-2 hover:bg-gray-50'}>
				<LogOutButton />
			</div>
			<div className={'py-2 w-full flex items-center justify-center'}>
				<div className={'-ml-5'}>
					<TopNavBarChangeLanguage />
				</div>
			</div>
		</div>
	);
};

export default TopBarMobileMenuDropdown;

import BaseStore from '@/js/core/BaseStore';
import userStore from '@/js/modules/users/store/v2/UserStore';
import { errorHandler } from '@/js/helpers/errorHandler.ts';
import { AccountTypes, ForumCategories, LogoVariants } from '@/utils/Enums';
import ForumService from '../services/ForumService';
import { IForumData } from '../types/IForumData';
import { __ } from '@/utils/Trans';
import { PageRoutes } from '@/utils/PageRoutes';
import { RowStates } from '@/js/core/enums/RowStates';

interface IForumStore {
	forumData: {};
	forumList: [];
}

const forumStore = {
	form: {
		blokId: '',
		title: '',
		content: '',
		category: '',
		isPinned: false,
		userStatus: RowStates,
		accountTypes: [],
	},
	forumData: {},
	forumList: [],
};

class ForumStore extends BaseStore<IForumStore, ForumService> {
	initializeStore() {
		return this.useStore();
	}

	resetForm() {
		this.state.form = this.getInitialForm();
	}

	async create(blokId: string) {
		try {
			this.state.isSaving = true;

			return await this.service.create({ ...this.state.form, blokId });
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async update(id, payload) {
		try {
			this.state.isSaving = true;

			return await this.service.update(id, payload);
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async fetchBlokAllForums(blokId: string, query: string): Promise<IForumData[]> {
		this.state.isLoading = true;
		const result = await this.service.fetchAll(blokId, query);
		this.state.posts = result?.data?.result || [];
		this.state.isLoading = false;
		return result?.data?.result;
	}

	async fetchForum(forumSlug: string): Promise<IForumData> {
		try {
			this.state.isLoading = true;
			const result = await this.service.fetchOneBy(forumSlug);

			this.state.form = result?.data?.result;

			return result?.data?.result;
		} catch (error) {
			this.goToForbidden(error);
			throw error;
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchForumInfo(forumSlug: string): Promise<IForumData> {
		try {
			this.state.isLoading = true;
			const result = await this.service.fetchInfoBy(forumSlug);

			this.state.form = result?.data?.result;

			return result?.data?.result;
		} catch (error) {
			this.goToForbidden(error);
			throw error;
		} finally {
			this.state.isLoading = false;
		}
	}

	async delete(id) {
		try {
			this.state.isSaving = true;

			return await this.service.delete(id);
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	validateBeforeSave() {
		const isEditorEmpty = ['<p></p>', null, ''].includes(this.state.form.content);

		return !isEditorEmpty;
	}

	isVisitor() {
		return userStore.isVisitor();
	}

	getAccountType(): AccountTypes {
		return userStore.getAccountType();
	}

	getInitialForm() {
		return {
			blokId: '',
			title: '',
			content: '',
			category: '', //ForumCategory.General
			isPinned: false,
		};
	}

	getForumCategoryLabels(category) {
		switch (category) {
			case ForumCategories.Announcement:
				return __('ForumCategory.Announcement');
			case ForumCategories.QuestionDiscussion:
				return __('ForumCategory.Discussion');
			case ForumCategories.Support:
				return __('ForumCategory.Support');
			case ForumCategories.General:
				return __('ForumCategory.General');
			default:
				return __('ForumCategory.General');
		}
	}

	getLogoVariant(accountType) {
		switch (accountType) {
			case AccountTypes.BlokOwner:
				return LogoVariants.Blok;
			case AccountTypes.CompanyOwner:
				return LogoVariants.Company;
			case AccountTypes.Talent:
				return LogoVariants.Talent;
			default:
				return LogoVariants.Blok;
		}
	}

	forumDetailPageUrl(blokSlug, forumSlug, withAppUrl = false) {
		if (withAppUrl) {
			return `${process.env.VITE_FE_URL}${PageRoutes.ForumDetailPage.replace(
				':blokSlug',
				blokSlug
			).replace(':forumSlug', forumSlug)}`;
		}

		return PageRoutes.ForumDetailPage.replace(':blokSlug', blokSlug).replace(
			':forumSlug',
			forumSlug
		);
	}
}

export default new ForumStore(forumStore, ForumService) as ForumStore;

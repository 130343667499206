import React from 'react';
import { __ } from '@/utils/Trans';
import blokStore from '@/js/modules/bloks/store/BlokStore';
import BloksTable from '@/js/modules/bloks/components/BloksTable';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { AccountTypes } from '@/utils/Enums';
import SwitchUserIcon from '@/js/components/SwitchUser/SwitchUserIcon';
import { Account } from '@/utils/Types';
import useUserStore from '@/js/modules/users/store/useUserStore';
import { useLocation } from 'react-router-dom';
import userStore from '@/js/modules/users/store/v2/UserStore';

interface IJoinedBloksPage { }

export enum Tabs {
	Approved = 'approved',
	Pending = 'pending',
	Invites = 'invites'
}

export default function JoinedBloksPage(props: IJoinedBloksPage) {
	blokStore.initializeStore();

	const initialCols = [
		{ name: __("BloksPage.Columns.Bloks"), classes: "" },
		{ name: __("BloksPage.Columns.Description"), classes: "" }
	]

	const [selectedTab, setSelectedTab] = React.useState<Tabs>(Tabs.Approved);
	const [columns, setColumns] = React.useState<typeof initialCols>(initialCols);

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const fromCompanyPage = params.get('fromCompanyPage') === 'true';

	const me = {
		id: useUserStore.getState().user.id,
		avatarUrl: useUserStore.getState().user.avatarUrl,
		name: `${useUserStore.getState().user.firstName} ${useUserStore.getState().user.lastName}`,
		accountType: AccountTypes.Talent,
	};

	const accountInfo = userStore.state.accountInfo;
		const myCompanies = accountInfo
			.filter((account) => account.accountType === AccountTypes.CompanyOwner)
			.map((account) => ({
				id: account.id ?? '',
				name: account.name ?? '',
				slug: account.slug ?? '',
				avatarUrl: account.avatarUrl ?? '',
				accountType: AccountTypes.CompanyOwner,
			}));

	const [selectedUser, setSelectedUser] = React.useState<Account>(fromCompanyPage ? myCompanies[0] : me);

	const { data, isLoading } = useQuery({
		queryKey: ['joinedBloks', selectedTab, selectedUser],
		queryFn: async () => {
			await blokStore.fetchJoinedBloksByAccountType(selectedUser?.accountType || AccountTypes.Talent);
			return blokStore.getFilteredBloksBy(selectedTab);
		},
	})

	const setTab = (value: Tabs) => {
		setSelectedTab(value);
		setColumns(getColumnsForUser(selectedUser, value));
	}

	React.useEffect(() => {
		setColumns(getColumnsForUser(selectedUser, selectedTab));
	}, [selectedUser, selectedTab]);

	const getColumnsForUser = (user: Account, tab: Tabs) => {
		if (user?.accountType === AccountTypes.Talent) {
			if (tab === Tabs.Approved) {
				return [
					{ name: __("BloksPage.Columns.Bloks"), classes: "" },
					{ name: __("BloksPage.Points"), classes: 'text-center' },
					{ name: __("BloksPage.Columns.Description"), classes: "" },
					{ name: "", classes: "" }
				];
			}
			if (tab === Tabs.Invites) {
				return [
					{ name: __("BloksPage.Columns.Bloks"), classes: "" },
					{ name: __("BloksPage.Columns.Description"), classes: "" },
					{ name: "", classes: "" }
				];
			}
			return [
				{ name: __("BloksPage.Columns.Bloks"), classes: "" },
				{ name: __("BloksPage.Columns.Description"), classes: "" }
			];
		} else if (user?.accountType === AccountTypes.CompanyOwner) {
			return [
				{ name: __("BloksPage.Columns.Bloks"), classes: "" },
				{ name: __("BloksPage.Columns.ApprovedAt"), classes: "" },
				{ name: __("BloksPage.Columns.JobPostCount"), classes: "" },
				{ name: '', classes: "" },
				{ name: '', classes: "" },
			];
		}
		return [];
	}

	const tabs = [
		{ name: __("BloksPage.Approved"), value: Tabs.Approved, condition: true },
		{ name: __("BloksPage.Pending"), value: Tabs.Pending, condition: true, },
		{ name: __("BloksPage.Invites"), value: Tabs.Invites, condition: selectedUser?.accountType === AccountTypes.Talent }
	]

	const handleUserChange = (user: Account) => {
		setSelectedUser(user);
	};

	return (
		<div className={'bg-neutral-50 flex flex-col w-full min-h-screen'}>
			<Tab.Group
				as={"div"}
				defaultIndex={0}
				onChange={(index) => setTab(tabs[index].value)}
				className={"mt-8 mx-0 md:mx-[30px] py-6 pl-5 px-0 md:px-[30px] bg-white md:rounded-2xl border-2 border-shade-stroke mb-48"}
			>
				{
					isLoading
						? <Skeleton count={3} />
						: selectedTab && (
							<>
								<div className='flex gap-2.5 items-center mb-6'>
									<h1 className={"text-shade-black text-header-bold-h8"}>
										{__("BloksPage.EmptyCardTitle")}
									</h1>
									<SwitchUserIcon onUserChange={handleUserChange} initialSelectedUser={selectedUser} onJoinedBloksPage />
								</div>
								<Tab.List className={'flex gap-3 items-center'}>
									{tabs.map((tab) => (
										tab.condition && (
											<Tab
												key={tab.value}
												value={tab.value}
												className={({ selected }) => clsx(
													'text-neutral-500 px-3 py-[9px] ring-0 outline-none rounded-full border border-shade-stroke text-label-regular-l8',
													{
														'bg-primary-500 text-white !text-label-semibold-l8 !border-0': selected
													}
												)}
											>
												{__(tab.name)}
											</Tab>
										)
									))}
								</Tab.List>
								<Tab.Panels
									as='div'
									className="max-h-max overflow-hidden mt-4"
								>
									<div className="w-full overflow-x-auto overflow-y-hidden">
										<BloksTable
											columns={columns}
											bloks={data || []}
											selectedTab={selectedTab}
											selectedUser={selectedUser}
										/>
									</div>
								</Tab.Panels>
							</>
						)
				}
			</Tab.Group >
		</div>
	)
}

import React from 'react';
import Modal from '@/js/components/Modal/Modal';
import { __ } from '@/utils/Trans';
import userStore from '@/js/modules/users/store/v2/UserStore';
import ContractCheckbox from '../../contracts/components/ContractCheckbox/ContractCheckbox';
import { ContractRoutes } from '../../contracts/services/ContractRoutes';
import Button from '@/js/components/Buttons/Button';

interface IContractUpdateModal {
	onClose: () => void;
}

const ContractUpdateModal = (props: IContractUpdateModal) => {
	const [checked, setChecked] = React.useState(false);

	const handleSaveButtonClick = async () => {
		userStore.updateHasAgreements().then(() => {
			props.onClose();
		});
	};

	return (
		<Modal name={'ContractUpdateModal'} onClose={() => {}} closeClasses="hidden">
			<div className={'flex flex-col items-center gap-4'}>
				<div className="bg-primary-50 h-[80px] w-[80px] rounded-full flex justify-center items-center text-3xl">
					📝
				</div>
				<h1 className={'text-shade-black text-header-semibold-h7'}>
					{__('ContractUpdateModal.Title')}
				</h1>
				<p className={'text-gray-700 text-body-regular-b5 text-center'}>
					{__('ContractUpdateModal.Description')}
				</p>
				<ContractCheckbox
					error={userStore.state.errors}
					to={ContractRoutes.TalentMemberShipAgreement}
					label={'CreateTalentPage.TalentAgreement'}
					labelClass={'text-xs font-medium text-gray-500 leading-4'}
					name={'hasTalentUserAgreement'}
					checked={checked}
					onChecked={(e) => setChecked(e.target.checked)}
				/>
				<Button
					disabled={!checked}
					name={'editAboutBtn'}
					attrs={{
						onClick: () => {
							handleSaveButtonClick();
						},
					}}
					classes={'!text-sm'}
				>
					{__('ContractUpdateModal.Button')}
				</Button>
			</div>
		</Modal>
	);
};

export default ContractUpdateModal;

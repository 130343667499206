import BaseStore from '@/js/core/BaseStore';
import { IPackageStore } from './PackageTypes';
import PackageService from '../services/PackageService';
import {
	AccountTypes,
	BlogPostCategory,
	PageTranslation,
	ServiceCategory,
	UIState,
} from '@/utils/Enums';
import { __ } from '@/utils/Trans';
import { RowStates } from '@/js/core/enums/RowStates';
import { Currencies } from '../../../../../../api/core/enums/currency.enum';
import blokStore from '@/js/modules/bloks/store/BlokStore';
import { errorHandler } from '@/js/helpers/errorHandler';
import { getFixedT } from 'i18next';
import accounting from 'accounting';

const packageStore = {
	form: {
		id: '',
		ownerType: '',
		slug: '',
		price: '',
		ownerId: '',
		category: '',
		state: '',
		imageUrl: '',
		languages: [
			{
				[PageTranslation.EN]: {
					name: '',
					description: '',
				},
				[PageTranslation.TR]: {
					name: '',
					description: '',
				},
			},
		],
	},
	owners: [],
};

class PackageStore extends BaseStore<IPackageStore, PackageService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchPackage(id: string) {
		this.state.isLoading = true;
		try {
			const { data } = await this.service.fetchPackage(id);

			return data.result;
		} catch (error) {
			this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchForForm(id: string) {
		try {
			this.state.isLoading = true;

			const { data } = await this.service.fetchForForm(id);

			// if (data.result.ownerType === AccountTypes.BlokOwner) {
			// 	await this.fetchBloks();
			// }
			this.state.form = data.result;

			return data.result;
		} catch (error) {
			if (this.goToForbidden(error)) {
				return this.goToForbidden(error);
			}

			return this.goToNotFound();
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateService(id: string) {
		try {
			this.state.isSaving = true;

			const { data } = await this.service.updateService(id, this.getPayload());

			return data.result;
		} catch (error) {
			console.log(error);
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async createService(blokId: string) {
		try {
			this.state.isSaving = true;

			const { data } = await this.service.createService({
				...this.getPayload(),
				ownerId: blokId,
				ownerType: AccountTypes.BlokOwner,
			});

			return data;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async createPaywallService(blokId: string) {
		try {
			this.state.isSaving = true;

			if (!blokId) {
				throw new Error('Blok not found');
			}

			const { data } = await this.service.createService({
				...this.getPayload(),
				state: RowStates.Published,
				ownerId: blokId,
				ownerType: AccountTypes.BlokOwner,
				category: ServiceCategory.ForBlokPaywall,
			});

			return data;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async createAdvert(payload: unknown) {
		try {
			this.state.isSaving = true;

			const { data } = await this.service.createService(payload);

			return data;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async fetchBloks() {
		await blokStore.fetchBloksForAdmin();

		this.state.owners = blokStore.state.bloks.map((blok) => {
			return { label: blok.name, value: blok.id };
		});

		this.state.owners.unshift({
			label: __('Services.AllBloks'),
			value: 'all',
		});
	}

	async fetchAllSponsorships(pageParam: number, query?: string) {
		this.state.isLoading = true;
		try {
			const response = query
				? await this.service.fetchAllSponsorships(pageParam, query)
				: await this.service.fetchAllSponsorships(pageParam);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	getPayload() {
		const tr = this.state.form?.languages[0][PageTranslation.TR];
		const en = this.state.form?.languages[0][PageTranslation.EN];

		return {
			...this.state.form,
			name: tr.name || en.name,
			description: tr.description || en.description,
			slug: tr.name || en.name,
			price: String(accounting.unformat(this.state.form.price)),
			currency: this.state.form.currency ? this.state.form.currency : Currencies.TRY,
			languages: [
				{
					[PageTranslation.EN]: {
						...en,
						category: this.getCategoryTranslation(this.state.form.category, PageTranslation.EN),
					},
					[PageTranslation.TR]: {
						...tr,
						category: this.getCategoryTranslation(this.state.form.category, PageTranslation.TR),
					},
				},
			],
		};
	}

	getCategoryTranslation(category: BlogPostCategory, lang: PageTranslation) {
		const t = (key: string) => {
			const translate = getFixedT(lang, null, key);
			return () => translate(key);
		};

		switch (category) {
			case BlogPostCategory.ForBlokOwners:
				return t('BlogPosts.ForBlokOwners')();
			case BlogPostCategory.ForCompanies:
				return t('BlogPosts.ForCompanies')();
			case BlogPostCategory.ForTalents:
				return t('BlogPosts.ForTalents')();
			default:
				return '';
		}
	}

	getPageTranslations() {
		return this.getOptionsArrayFromEnum(PageTranslation, true);
	}

	getOptionsArrayFromEnum(enumObject, isLabelUpperCase = false) {
		return Object.entries(enumObject).map(([key, value]) => {
			return { value, label: isLabelUpperCase ? String(value).toUpperCase() : value };
		}) as { value: string; label: string }[];
	}

	setValueByLanguage(language: PageTranslation, fieldName: string, value: string) {
		if ((language && fieldName) || value) {
			this.state.errors['languages'] = '';
			this.state.form.languages[0][language][fieldName] = value;
			this.state.errors[fieldName] = '';
		}
	}

	getValueByLanguage(language: PageTranslation, fieldName: string) {
		if (typeof this.state.form.languages === 'object') {
			return this.state.form.languages[0]?.[language]?.[fieldName];
		}

		return this.state.form.languages?.[0]?.[language]?.[fieldName];
	}

	getNewCategories() {
		return [
			{ value: ServiceCategory.ForServices, label: __('Services.Services') },
			{ value: ServiceCategory.ForProducts, label: __('Services.Product') },
			{ value: ServiceCategory.ForEducation, label: __('Services.Education') },
			{ value: ServiceCategory.ForConsulting, label: __('Services.Consulting') },
			{ value: ServiceCategory.ForSponsorship, label: __('Services.Sponsorship') },
			{ value: ServiceCategory.ForMembership, label: __('Services.Membership') },
			{ value: ServiceCategory.ForBlokPaywall, label: __('Services.BlokPaywall') },
			{ value: ServiceCategory.ForOther, label: __('Services.OtherCategories') },
		];
	}

	getCategoriesForServices() {
		return [
			{ value: ServiceCategory.ForServices, label: __('Services.Services') },
			{ value: ServiceCategory.ForProducts, label: __('Services.Product') },
			{ value: ServiceCategory.ForEducation, label: __('Services.Education') },
			{ value: ServiceCategory.ForConsulting, label: __('Services.Consulting') },
			{ value: ServiceCategory.ForSponsorship, label: __('Services.Sponsorship') },
			{ value: ServiceCategory.ForMembership, label: __('Services.Membership') },
			{ value: ServiceCategory.ForOther, label: __('Services.OtherCategories') },
		];
	}

	getCategoryLabels(category: string) {
		switch (category) {
			case 'forServices':
				return __('Services.Services');
			case 'forProducts':
				return __('Services.Product');
			case 'forEducation':
				return __('Services.Education');
			case 'forConsulting':
				return __('Services.Consulting');
			case 'forSponsorship':
				return __('Services.Sponsorship');
			case 'forMembership':
				return __('Services.Membership');
			case 'forBlokPaywall':
				return __('Services.BlokPaywall');
			case 'forOther':
				return __('Services.OtherCategories');
			default:
				return __('Services.OtherCategories');
		}
	}

	getStates(isAdmin) {
		return isAdmin
			? [
					{ value: RowStates.Published, label: __('AdminCreateServicePage.Publish') },
					{ value: RowStates.Pending, label: __('AdminCreateServicePage.Pending') },
					{ value: RowStates.Unpublished, label: __('AdminCreateServicePage.Unpublish') },
			]
			: [
					{ value: RowStates.Published, label: __('AdminCreateServicePage.Publish') },
					{ value: RowStates.Unpublished, label: __('AdminCreateServicePage.Unpublish') },
			];
	}

	getState(state) {
		switch (state) {
			case RowStates.Published:
				return { state: UIState.Success, text: __('OneTimePackage.Published') };
			case RowStates.Pending:
				return { state: UIState.Warning, text: __('OneTimePackage.Pending') };
			case RowStates.Unpublished:
				return { state: UIState.Danger, text: __('OneTimePackage.Declined') };
			default:
				return { state: UIState.Warning, text: __('OneTimePackage.Pending') };
		}
	}

	getCurrencies() {
		return [{ label: Currencies.TRY, value: Currencies.TRY }];
	}

	getOwnerTypes() {
		return [{ value: AccountTypes.BlokOwner, label: __('Services.BlokOwner') }];
	}

	async createDefaultPackage(blokId: string) {
		const defaultPackageAdvert = {
			name: 'publish-job-post',
			description: 'Blok‘taki adaylardan başvuru toplayın!',
			slug: 'publish-job-post',
			ownerId: blokId,
			languages: [
				{
					en: { name: 'Post a Job', description: 'Companies can post job on your Blok.' },
					tr: { name: 'İlan Yayınla', description: 'Blok‘taki adaylardan başvuru toplayın!' },
				},
			],
			ownerType: 'blokOwner',
			price: '4500',
			currency: 'TRY',
			state: 'published',
		};

		return await this.createAdvert(defaultPackageAdvert);
	}

	resetForm() {
		this.state.form = {
			id: '',
			slug: '',
			price: '',
			category: '',
			state: '',
			languages: [
				{
					[PageTranslation.EN]: {
						name: '',
						description: '',
					},
					[PageTranslation.TR]: {
						name: '',
						description: '',
					},
				},
			],
		};
	}
}

export default new PackageStore(packageStore, PackageService);

import { useOutletContext } from "react-router-dom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import { __ } from "@/utils/Trans";
import OnboardingBottom from "../OnboardingBottom";
import TextArea from "@/js/components/TextArea/TextArea";
import TextBox from "@/js/components/TextBox/TextBox";
import blokOwnerStore from "@/js/modules/bloks/store/BlokOwnerStore";
import CheckBox from "@/js/components/CheckBox/CheckBox";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import { defaultTitleMaxLength } from "@/utils/Regex";

export default function OnboardingBlokOwnerAbout() {
	const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>();

	const handleOnContinueClick = () => {
		blokOwnerStore.resetErrors();

		if (blokOwnerStore.state?.form?.username?.length > defaultTitleMaxLength) {
			blokOwnerStore.state.errors = {
				...blokOwnerStore.state.errors,
				username: "Validations.DefaultTitleMaxLength"
			}
			return
		}

		handleChangeStep();
	};

	return (
		<div className="flex flex-col justify-center items-center ">
			<div className="onboarding-full-height no-scrollbar max-w-2xl w-full overflow-y-auto py-10 px-4 md:justify-center">
				<h1 className="text-header-bold-h8 md:text-header-bold-h5 w-full text-shade-black md:w-auto md:text-center">
					{__("OnboardingPage.BlokOwnerAbout.Title")}
				</h1>
				<div className="flex flex-col justify-center gap-5 mt-10">
					<TextBox name={"txt_Username"}>
						<TextBox.Label >
							{__("OnboardingPage.BlokOwnerAbout.Name")}
						</TextBox.Label>
						<TextBox.Input
							name={"username"}
							error={blokOwnerStore.state.errors}
							attr={{
								name: "username",
								maxLength: 200,
								value: blokOwnerStore.state?.form?.username,
								placeholder: __("OnboardingPage.BlokOwnerAbout.NamePlaceholder"),
								onChange: (e) => blokOwnerStore.set("username", e.target.value),
							}}
						/>
					</TextBox>
					<div>
						<TextArea
							label={__("OnboardingPage.BlokOwnerAbout.Bio")}
							isOptional
							rowCount={8}
							maxWordCount={1000}
							error={blokOwnerStore.state.errors}
							name={"description"}
							placeholder={__("OnboardingPage.BlokOwnerAbout.BioPlaceholder")}
							attrs={{
								name: "bio",
								value: blokOwnerStore.state?.form?.bio || "",
								onChange: (e) => blokOwnerStore.set("bio", e.target.value),
							}}
						/>
						<div className="flex flex-row-reverse justify-end">
							<CheckBox className={"flex-row-reverse"}>
								<CheckBox.Label htmlFor={"isBlokOwnerVisible"}>
									<div className="text-shade-black text-body-regular-b7  text-base font-normal leading-normal">
										{__("OnboardingPage.BlokOwnerAbout.CheckBox")}
									</div>
								</CheckBox.Label>
								<CheckBox.Input
									id={"isBlokOwnerVisible"}
									name={"isBlokOwnerVisible"}
									error={blokOwnerStore.state.errors}
									attrs={{
										name: "isBlokOwnerVisible",
										value: blokOwnerStore.state?.form?.isBlokOwnerVisible,
										checked: blokOwnerStore.state?.form?.isBlokOwnerVisible,
										onChange: (e) => {
											blokStore.set('isBlokOwnerVisible', e.target.checked);
											blokOwnerStore.set('isBlokOwnerVisible', e.target.checked);
										}
									}}
								/>
							</CheckBox>
						</div>
					</div>
				</div>
			</div>
			<OnboardingBottom
				onClick={handleOnContinueClick}
				disabled={!blokOwnerStore.state?.form?.username}
			/>
		</div>
	);
}

import BaseStore from '@/js/core/BaseStore';
import { IJobStoreState, UserAccountView } from './UserTypes';
import UserService from '@/js/modules/users/services/UserService';
import { AccountTypes, ReferralTypes } from '@/utils/Enums';
import { PageRoutes } from '@/utils/PageRoutes';
import { errorHandler } from '@/js/helpers/errorHandler';
import localStorage from '@/js/helpers/localStorage';

const UserStore = {
	accountType: [],
	accountInfo: [],
	user: '',
	createPasswordPage: {
		password: '',
		token: '',
		hasExplicitConsent: false,
		hasTalentUserAgreement: false,
		hasNotificationPermission: false,
	},
	showContractModal: false,
	isLoading: false,
};

interface AccountsType {
	accountType: AccountTypes;
}

interface AccountsInfoType {
	accountType: AccountTypes;
	id: string;
	name: string;
	slug: string;
	avatarUrl: string;
}

class UserStores extends BaseStore<IJobStoreState, UserService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchAccountTypeForPerks(): Promise<string> {
		try {
			this.state.isLoading = true;

			const result = await this.service.fetchAccountType();
			return result.data?.result?.accountType;
		} catch {
			return Promise.resolve('');
		} finally {
			this.state.isLoading = false;
		}
	}

	async isEmailExists(email: string): Promise<boolean> {
		try {
			this.state.isLoading = true;

			const result = await this.service.isEmailExists(email);
			return result.data.result;
		} catch {
			return Promise.resolve(false);
		} finally {
			this.state.isLoading = false;
		}
	}

	async verify(token: string): Promise<boolean> {
		try {
			this.state.isLoading = true;

			const result = await this.service.verify({ token });

			return result.data.result;
		} catch (error: any) {
			const errors = error.response.data.validations?.reduce(
				(acc, curr) => ({
					...acc,
					[curr.field]: curr.code,
				}),
				{}
			);

			if (Object.keys(errors!).length) {
				this.state.hasError = true;
				this.state.errors = errors;
			}

			return Promise.resolve(false);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchAccountType(): Promise<UserAccountView[] | undefined> {
		try {
			this.state.isLoading = true;

			const result = await this.service.fetchAccountType();
			const userAccountTypes: AccountsType[] = result.data.result.map(
				(accountType: AccountsInfoType) => accountType.accountType as AccountTypes
			);

			this.state.accountType = userAccountTypes;
			this.state.accountInfo = result.data.result.map((account: AccountsInfoType) => ({
				accountType: account.accountType,
				id: account.id ?? '',
				name: account.name ?? '',
				slug: account.slug ?? '',
				avatarUrl: account.avatarUrl ?? '',
			}));

			return result.data.result;
		} catch {
			return Promise.resolve(undefined);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchMe() {
		try {
			this.state.isLoading = true;

			const result = await this.service.me();
			this.state.user = result.data.result;
			this.state.showContractModal = result.data.result.hasAgreements === false;

			return result.data.result;
		} catch {
			return Promise.resolve();
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateLanguage(payload) {
		try {
			const result = await this.service.updateLanguage(payload);

			return Promise.resolve(result.data.result);
		} catch {
			return Promise.resolve();
		}
	}

	async resendVerificationEmail(token?) {
		try {
			await this.service.resendVerificationEmail(token);
		} catch {
			return Promise.resolve();
		}
	}

	async updatePassword(token: string) {
		try {
			this.state.isLoading = true;

			const result = await this.service.updatePassword({
				...this.state.createPasswordPage,
				token,
			});

			return result.data.result;
		} catch (error) {
			this.goToForbidden(error);
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isLoading = false;
		}
	}

	async getInviteInfo(id: string) {
		try {
			this.state.isLoading = true;

			const result = await this.service.getUserInviteInfo(id);
			return result.data.result;
		} catch (error) {
			return;
		} finally {
			this.state.isLoading = false;
		}
	}

	async sendImportRequest(req: any) {
		try {
			this.state.isLoading = true;

			const result = await this.service.sendImportRequest(req);
			return result.data.result;
		} catch (error) {
			const errorMessages = errorHandler(error);
			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isLoading = false;
		}
	}

	async sendUpdateImportRequest(req: any) {
		try {
			this.state.isLoading = true;

			const result = await this.service.updateImportRequest(req);
			return result.data.result;
		} catch (error) {
			const errorMessages = errorHandler(error);
			if (Object.keys(errorMessages!).length) {
				this.state.hasError = true;
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchUsersAndPaywallPaymentsInfo(blokId: string) {
		try {
			this.state.isLoading = true;

			const response = await this.service.fetchUsersAndPaywallPaymentsInfo(blokId);
			return response.data.result;
		} catch (error) {
			console.error(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	isVisitor() {
		return !localStorage.has('user');
	}

	isCompanyOwnerAccountType() {
		return this.state.accountType.includes(AccountTypes.CompanyOwner);
	}

	isTalentAccountType() {
		if (this.isVisitor()) {
			return false;
		}
		return true;
	}

	isBlokOwnerAccountType() {
		return this.state.accountType.includes(AccountTypes.BlokOwner);
	}

	getAccountType() {
		return AccountTypes.Talent;
	}

	getRedirectPagesByAccountType() {
		return PageRoutes.UserFeedPage;
	}

	getRedirectPagesByAccountTypeNavBar() {
		return PageRoutes.UserFeedPage;
	}

	getRouteByReferral() {
		const params = new URLSearchParams(window.location.search as string);
		const referralType = params.get('referralType') as ReferralTypes;

		switch (referralType) {
			case ReferralTypes.Company:
				return PageRoutes.CompanyCreate;
			case ReferralTypes.Talent:
				return PageRoutes.TalentCreate;
			case ReferralTypes.Blok:
				return PageRoutes.BlokOwnerCreate;
			default:
				return PageRoutes.Onboarding;
		}
	}

	async updateHasAgreements() {
		try {
			this.state.isLoading = true;

			const result = await this.service.updateHasAgreements();
			return result.data.result;
		} catch (error) {
			console.error(error);
		} finally {
			this.state.isLoading = false;
		}
	}
}

export default new UserStores(UserStore, UserService);

import BaseService from "@/js/core/BaseService";
import { TalentEndpoints } from "@/js/modules/talent/services/TalentEndpoints";

export default class TalentService extends BaseService {
	async fetchTalent(userId) {
		return this.api().get(`${TalentEndpoints.FetchTalent.replace(':id', userId)}`);
	}

	async createTalent(payload) {
		return this.api().post(`${TalentEndpoints.CreateTalent}`, payload);
	}

	async createTalentAccount(payload) {
		return this.api().post(`${TalentEndpoints.createTalentAccount}`, payload);
	}

	async verifyAndCreateTalentAccount(token) {
		return this.api().post(`${TalentEndpoints.verifyTalentAccount.replace(':token', token)}`, {});
	}

	async updateTalent(userId, payload) {
		return this.api().put(`${TalentEndpoints.UpdateTalent.replace(':id', userId)}`, payload);
	}

	async updateWorkPreferences(userId, payload) {
		return this.api().put(`${TalentEndpoints.UpdateWorkPreferences.replace(':id', userId)}`, payload);
	}

	async fetchTalentByAuth() {
		return this.api().get(TalentEndpoints.FetchTalentByAuth);
	}

	async updateAvatarImage(id: string, data: unknown) {
		return this.api().put(TalentEndpoints.UploadAvatarPhoto.replace(':id', id), data);
	}

	async uploadCoverPhoto(id: string, data: unknown) {
    return this.api().put(TalentEndpoints.UploadCoverPhoto.replace(':id', id), data);
  }

	async createForEvent(data: unknown) {
		return this.api().post(TalentEndpoints.createForEvent, data);
	}

	async updateImportedTalentData(data: unknown) {
		return await this.api().post(TalentEndpoints.UpdateImportedCompany, data);
	}

	async fetchTalentForVisitorPage(userId) {
		return this.api().get(`${TalentEndpoints.FetchTalentForVisitorPage.replace(':id', userId)}`);
	}
}

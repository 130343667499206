import BaseStore from "@/js/core/BaseStore";
import JobPostTalentService from "@/js/modules/jobs/services/JobPostTalentService";
import jobStore from "@/js/modules/jobs/store/JobStore";
import rewardJobsStore from "@/js/modules/jobs/store/RewardJobsStore";
import userStore from '@/js/modules/users/store/v2/UserStore';
import { AccountTypes } from '@/utils/Enums';

interface IJobPostTalentStore { }

const jobPostTalentStore = {
	jobPost: {},
	talents: [],
	applications: [],
	linkedInTalents: [],
}

class JobPostTalentStore extends BaseStore<IJobPostTalentStore, JobPostTalentService> {
	initializeStore() {
		return this.useStore();
	}

	async getHasTalentAcceptedReferralStatus(id: string) {
		try {
			this.state.isLoading = true;
			const { data } = await this.service.getHasTalentAcceptedReferralStatus(id);
			return data?.result;
		} catch (error) {
			console.log(error)
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateJobPostTalentReferralStatus(id: string, payload: { hasTalentAcceptedReferral: boolean }) {
		try {
			this.state.isSaving = true;
			return this.service.updateJobPostTalentReferralStatus(id, payload);
		} catch (error) {
			return Promise.resolve(false);
		} finally {
			this.state.isSaving = false;
		}
	}

	async createJobPostTalent(payload) {
		await this.service.createJobPostTalent(payload);
	}

	async suggestionTalent(payload) {
		await this.service.suggestionTalent(payload);
	}

	async suggestTalentWithLinkedIn(payload: unknown) {
		await this.service.suggestTalentWithLinkedIn(payload);
	}

	async fetchTalents({ jobPostId, blokId }, sortByScore?: boolean) {
		const { data } = await this.service.fetchTalents({ jobPostId, blokId }, sortByScore);
		this.state.talents = data?.result || [];
	}

	async fetchForApplicationsPage(jobPostId) {
		this.state.isLoading = true;
		const jobPost = await jobStore.fetchJobPost(jobPostId);
		const applications = await this.service.fetchJobPostApplications(jobPostId);

		this.state.jobPost = jobPost?.result;
		this.state.applications = applications?.data?.result || [];

		this.state.isLoading = false;
	}

	getReward(rewardCommission) {
		return rewardJobsStore.getReward(rewardCommission);
	}

	isPublished(state) {
		return jobStore.isPublished(state);
	}

	isDraft(state) {
		return jobStore.isDraft(state);
	}

	async updateState(id, state) {
		return this.service.updateState(id, { state });
	}

	async fetchTalentsByReferredId() {
		this.state.isLoading = true;
		const accountTypes = await userStore.fetchAccountType();

		const goToForbidden = !accountTypes?.some(account => account.accountType === AccountTypes.BlokOwner);

		if (goToForbidden) {
			this.goToForbidden(true);
			return;
		}

		const { data } = await this.service.fetchTalentsByReferredId();
		this.state.talents = data?.result || [];
		this.state.isLoading = false;

		return data?.result;
	}
}

export default new JobPostTalentStore(jobPostTalentStore, JobPostTalentService) as JobPostTalentStore;

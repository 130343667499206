import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import userStore from '@/js/modules/users/store/v2/UserStore';
import LocalStorage from '@/js/helpers/localStorage';
import OnboardingProgresBar from '@/js/modules/onboarding/components/OnboardingProgresBar';
import { __ } from '@/utils/Trans';
import blokOwnerStore from '@/js/modules/bloks/store/BlokOwnerStore';
import { useTranslation } from 'react-i18next';
import blokStore from '@/js/modules/bloks/store/BlokStore';
import {
	OnboardingOutletContextType,
	OnboardingSteps,
	blokOwnerOnboardingSteps,
	onboardingSteps,
	resetOnboardingLocalStorage,
} from '@/js/modules/onboarding/constants/onbardingConstants';
import OnboardingBlokInfo from '@/js/modules/onboarding/components/OnboardingBlokInfo';
import { AccountTypes } from '@/utils/Enums';

export default function BlokOwnerLayout() {
	blokStore.initializeStore();
	blokOwnerStore.initializeStore();
	userStore.initializeStore();

	const [stepName, setStepName] = React.useState<OnboardingSteps>(OnboardingSteps.CompanyInterest);
	const [currentStep, setCurrentStep] = React.useState<JSX.Element>(onboardingSteps[stepName]);
	const [searchParams] = useSearchParams();

	const handleChangeStep = (isBack?: boolean) => {
		const steps = LocalStorage.get('onboardingSteps') as OnboardingSteps[];

		const currentStepIndex = steps.findIndex((step) => step === stepName);

		if ((isBack && currentStepIndex === 0) || (!isBack && currentStepIndex === steps.length - 1)) {
			return;
		}

		const step = isBack ? steps[currentStepIndex - 1] : steps[currentStepIndex + 1];

		if (!step) {
			return;
		}

		setStepName(step);
		setCurrentStep(onboardingSteps[step]);

		handleSetPayloadToLocalStorageByAccountType();

		LocalStorage.set('lastOnboardingStep', step);
	};

	const clearPayloadForStore = () => {
		return blokStore.newClearFormData(), blokOwnerStore.newClearFormData();
	};

	const handleSetPayloadToLocalStorageByAccountType = () => {
		LocalStorage.set('onboardingPayload', blokStore.getCreatePayload());
	};

	React.useEffect(() => {
		const init = async () => {
			blokStore.newClearFormData();
			blokOwnerStore.newClearFormData();
			LocalStorage.set('onboardingSelectedAccountType', AccountTypes.BlokOwner);
			LocalStorage.set('onboardingSteps', blokOwnerOnboardingSteps);
			LocalStorage.set('lastOnboardingStep', blokOwnerOnboardingSteps[0]);
			// if (LocalStorage.has('lastOnboardingStep')) {
				// const lastOnboardingStep = LocalStorage.get('lastOnboardingStep') as OnboardingSteps;
				// const isLastStepBlokOwnerStep = blokOwnerOnboardingSteps?.some(step => step === lastOnboardingStep);

			setStepName(blokOwnerOnboardingSteps[0]);
			setCurrentStep(onboardingSteps[blokOwnerOnboardingSteps[0]]);
			// }

			if (LocalStorage.has('onboardingPayload')) {
				LocalStorage.remove('onboardingPayload');
				clearPayloadForStore();
			}
		};
		//handleChangeStep();
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	

	const outletContext: OnboardingOutletContextType = {
		currentStep,
		setCurrentStep,
		handleChangeStep,
		//setMailActivationSucces,
	};

	return (
		<React.Fragment>
			{!searchParams.has('flow') && <OnboardingProgresBar />}

			{currentStep !== onboardingSteps[OnboardingSteps.BlokOwnerInterests] && <OnboardingBlokInfo />}
			<Outlet context={outletContext} />
		</React.Fragment>
	);
}

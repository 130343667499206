import Icon from '@/js/components/Icon/Icon';
import { Icons } from '@/utils/Icons';
import { __ } from '@/utils/Trans';
import React from 'react';
import { Link } from 'react-router-dom';
import subscriptionStore from '@/js/modules/subscriptions/store/SubscriptionStore.ts';
import PackagesIconFilled from '@/assets/icons/PackagesIconFilled.tsx';
import UpIcon from '@/assets/icons/UpIcon.tsx';

interface ITopBarDropdownMenuItem extends React.PropsWithChildren {
	classes: string;
	item: {
		to: string;
		name: string;
		translation: string;
		icon: Icons;
		type?: string;
		children?: {
			to: string;
			name: string;
			translation: string;
		}[];
	};
	isSubmenuOpen: boolean;
	toggleSubmenu: () => void;
}

const TopBarDropdownMenuItem = ({ item, classes, isSubmenuOpen, toggleSubmenu }: ITopBarDropdownMenuItem) => {
    return (
		<div className="relative group">
			<div className="cursor-pointer" onClick={item.children ? toggleSubmenu : undefined}>
				<div>
					{item.type === 'subscription' ? (
						<Link to={item.to} key={item.name} className={classes}>
							<div
								className={`flex items-center gap-2 font-semibold ${
									subscriptionStore.isPremiumPlan()
										? 'text-secondary-700'
										: subscriptionStore.isProPlan()
										? 'text-primary-500'
										: 'text-primary-700'
								}`}
							>
								{subscriptionStore.isPremiumPlan() ? <PackagesIconFilled /> : <UpIcon />}
								{subscriptionStore.noSubscription()
									? __('SubscriptionInfoPage.Subscribe')
									: subscriptionStore.isPremiumPlan()
									? __('SubscriptionInfoPage.Packages')
									: __('SubscriptionInfoPage.UpgradeYourPackage')}
							</div>
							{!subscriptionStore.noSubscription() && (
								<p
									className={`text-gray-700 mt-1 font-medium ${
										subscriptionStore.isPremiumPlan()
											? 'text-secondary-500'
											: subscriptionStore.isProPlan()
											? 'text-primary-700'
											: 'text-primary-500'
									}`}
								>
									{subscriptionStore.isPremiumPlan()
										? __('SubscriptionPlan.PremiumPlanText')
										: subscriptionStore.isProPlan()
										? __('SubscriptionPlan.ProPlanText')
										: __('SubscriptionPlan.BasicPlanText')}
								</p>
							)}
						</Link>
					) : item.children ? (
						<div className={classes}>
							<button className="ml-auto w-2 h-2 mr-4" onClick={() => toggleSubmenu()}>
								{isSubmenuOpen ? (
									<Icon name={Icons.ChevronUp} />
								) : (
									<Icon name={Icons.ChevronDown} />
								)}
							</button>

							<Link to={item.to} key={item.name} className={'flex flex-row-reverse gap-3'}>
								{__(item.translation)}
								<Icon name={item.icon} />
							</Link>
						</div>
					) : (
						<Link to={item.to} key={item.name} className={classes}>
							{__(item.translation)}
							<Icon name={item.icon} />
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default TopBarDropdownMenuItem;

import { useNavigate, useSearchParams } from "react-router-dom";
import React from 'react'
import { track } from "@/plugins/amplitude";
import { TrackerEvents } from "@/utils/TrackerEvents";
import userStore from "@/js/modules/users/store/v2/UserStore";
import LocalStorage from "@/js/helpers/localStorage";
import { __ } from "@/utils/Trans";
import talentStore from "@/js/modules/talent/store/TalentStore";
import { useTranslation } from "react-i18next";
import { VerificationErrors } from "@/js/modules/auth/constants/VerificationErrors";
import { resetOnboardingLocalStorage } from "@/js/modules/onboarding/constants/onbardingConstants";
import { PageRoutes } from "@/utils/PageRoutes";
import { toast } from "react-hot-toast";
import cookies from '@/js/helpers/cookies';

export default function OnboardingLayout() {
	talentStore.initializeStore()

	const { i18n } = useTranslation()
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const updateLanguage = async () => {
		await userStore.updateLanguage({ language: i18n.language });
	};

	const handleTokenVerification = async (token: string) => {
		resetOnboardingLocalStorage();
		track(TrackerEvents.VerifyAccount, { token });

		const result = await talentStore.verifyAndCreateTalentAccount( token );

		if (result?.data) {
			toast.success(__('OnboardingPage.EmailVerified'))

			LocalStorage.set("accessToken", token);
			cookies.setCookie("accessToken", token, 365);
			await new Promise((resolve) => setTimeout(resolve, 100));

			const user = await userStore.fetchMe();
			LocalStorage.set("user", JSON.stringify(user));

			const redirect = searchParams.get('redirect') || PageRoutes.UserFeedPage;
			navigate(redirect);
		} else {
			navigate(PageRoutes.WelcomePage);
		}
	};

	React.useEffect(() => {
		const init = async () => {
			const token = searchParams.get('token');
			if (token) {
				await handleTokenVerification(String(token));
			}
			await updateLanguage();
			navigate(PageRoutes.WelcomePage);
		};

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<>
		</>
	);
}

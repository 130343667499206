import BaseStore from '@/js/core/BaseStore';
import { IBlokTabStore } from '@/js/modules/bloks/store/BlokTypes';
import BlokTabService from '@/js/modules/bloks/services/BlokTabService';
import { RowStates } from '@/js/core/enums/RowStates';
import userStore from '@/js/modules/users/store/v2/UserStore';
import blokPublicStore from '@/js/modules/bloks/store/BlokPublicStore';
import companyStore from '@/js/modules/company/store/CompanyStore';
import jobPostTalentStore from '../../jobs/store/JobPostTalentStore';
import locationStore from "@/js/modules/locations/store/LocationStore.ts";

const blokTabStore = {
	talents: [],
	featuredTalents: [],
	companies: [],
	jobPosts: [],
	companyJobPosts: [],
	meta: {},
	referredTalents: [],
};

class BlokTabStore extends BaseStore<IBlokTabStore, BlokTabService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchImportedTalents(id: string, state: RowStates, page: number) {
		this.state.isLoading = true;

		try {
			await userStore.fetchAccountType();
			const response = await this.service.fetchImportedTalents(id, state, page);

			this.state.meta = response.data.result.meta
			this.setFormData(response.data.result.data);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchTalents(id: string, state: RowStates, page: number) {
		this.state.isLoading = true;

		try {
			await userStore.fetchAccountType();
			const response = await this.service.fetchTalents(id, state, page);

			this.state.meta = response.data.result.meta
			this.setFormData(response.data.result.data);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchTalentCount(id: string) {
		this.state.isLoading = true;
		try {
			const response = await this.service.fetchTalentCount(id);
			this.setTalentSize(response.data.result.data);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchCompanyCount(id: string) {
		this.state.isLoading = true;
		try {
			const response = await this.service.fetchCompanyCount(id);
			this.setCompanySize(response.data.result);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	locations() {
		let locations = locationStore.state.locations;

		locations = locations.map(option => {
			if (option.name === option.countryName) {
				return { label: option.name, value: option.id };
			}
			locations = locations.map(option => {
				if (option.name === option.countryName) {
					return { label: option.name, value: option.id };
				}

				return { label: `${option.name}, ${option.countryName}`, value: option.id };
			});
			return { label: `${option.name}, ${option.countryName}`, value: option.id };
		});

		return locations.sort(function (a, b) {
			return a.label.localeCompare(b.label, 'tr', { sensitivity: 'base' });
		});
	}

	async fetchCompanies(id: string, state: RowStates) {
		this.state.isLoading = true;

		try {
			const response = await this.service.fetchCompanies(id, state);

			this.setFormDataForCompanies(response.data.result);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchCompaniesForBlokOwner(blokId: string, query: string) {
		this.state.isLoading = true;
		const response = await this.service.fetchCompaniesForBlokOwner(blokId, query);

		this.setFormDataForCompanies(response.data.result.data);
		this.state.meta = response.data.result.meta;
		this.state.isLoading = false;
		return response.data.result.data;
	}

	async fetchImportedCompanies(id: string, state: RowStates) {
		this.state.isLoading = true;

		try {
			const response = await this.service.fetchImportedCompanies(id, state);

			this.setFormDataForCompanies(response.data.result);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchJobPosts(id: string, state?: RowStates) {
		this.state.isLoading = true;

		try {
			const response = await this.service.fetchJobPosts(id, state);

			this.setFormDataForJobPosts(response.data.result);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchCompanyJobPosts(id: string, state: RowStates) {
		this.state.isLoading = true;

		try {
			// TODO: fetching all company data just to compare ids is not efficient
			const company = await companyStore.fetchCompany();
			await this.fetchJobPosts(id);

			const companyJobPosts = this.state.jobPosts.filter((jobPost) => {
				return jobPost.company.id === company.id
			});

			const jobPosts = this.state.jobPosts.filter((jobPost) => {
				return jobPost.company.id !== company.id && jobPost.state === state
			});

			this.setFormDataForCompanyJobPosts(companyJobPosts);
			this.setFormDataForJobPosts(jobPosts);

			return companyJobPosts;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async updateBlokJobPostState(id: string, jobPostId: string, state: RowStates) {
		this.state.isSaving = true;

		try {
			await this.service.updateBlokJobPostState(id, jobPostId, state);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateBlokTalentState(id: string, talentId: string, state: RowStates) {
		this.state.isSaving = true;

		try {
			await this.service.updateBlokTalentState(id, talentId, state);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateBlokTalentsState(id: string, talentList: string[], state: RowStates) {
		this.state.isSaving = true;

		try {
			await this.service.updateBlokTalentsState(id, talentList, state);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateBlokCompanyState(id: string, companyId: string, state: RowStates) {
		this.state.isSaving = true;

		try {
			await this.service.updateBlokCompanyState(id, companyId, state);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateBlokCompaniesState(id: string, companyList: string[], state: RowStates) {
		this.state.isSaving = true;

		try {
			await this.service.updateBlokCompaniesState(id, companyList, state);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async referredTalentCount() {
		const data = await jobPostTalentStore.fetchTalentsByReferredId();
		this.state.referredTalents = data;
	}

	async deleteBlokTalent(id: string, talentId: string) {
		this.state.isSaving = true;

		try {
			await this.service.deleteBlokTalent(id, talentId);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async deleteBlokCompany(id: string, companyId: string) {
		this.state.isSaving = true;

		try {
			await this.service.deleteBlokCompany(id, companyId);
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isSaving = false;
		}
	}

	async fetchFeaturedTalents(id: string) {
		this.state.isLoading = true;

		try {
			await userStore.fetchAccountType();
			const response = await this.service.fetchFeaturedTalents(id);

			this.setFeaturedTalentsData(response.data.result.data);
			return response.data.result.data;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	isCompanyOwnerAccountType() {
		return userStore.isCompanyOwnerAccountType();
	}

	isTalentAccountType() {
		return userStore.isTalentAccountType();
	}

	isBlokOwnerAccountType() {
		return userStore.isBlokOwnerAccountType();
	}

	isOwner() {
		return blokPublicStore.state.info.isOwner //&& this.isBlokOwnerAccountType();
	}

	applicationType() {
		return blokPublicStore.state.info.applicationType;
	}

	blokName() {
		return blokPublicStore.state.info.name;
	}

	isVisitor() {
		return blokPublicStore.isVisitor();
	}

	setFormData(data) {
		this.state.talents = data
	}

	setTalentSize(data) {
		this.state.talentsCount = data
	}

	setCompanySize(data) {
		this.state.companyCount = data
	}

	setFormDataForCompanies(data) {
		this.state.companies = data
	}

	setFormDataForJobPosts(data) {
		this.state.jobPosts = data
	}

	setFormDataForCompanyJobPosts(data) {
		this.state.companyJobPosts = data
	}

	setFeaturedTalentsData(data) {
		this.state.featuredTalents = data
	}
	getFeaturedTalentsData() {
		return this.state.featuredTalents
	}
}

export default new BlokTabStore(blokTabStore, BlokTabService) as BlokTabStore;

import { Icons } from '@/utils/Icons';
import Avatar from '../../../components/Avatar/Avatar';
import TopBarMobileMenuDropdown from './TopBarMobileMenuDropdown';
import TopBarMobileMenuNavigation from './TopBarMobileMenuNavigation';
import TopBarUserInfo from '../author/AuthorInfo';
import useUserStore from '@/js/modules/users/store/useUserStore';
import { shallow } from 'zustand/shallow';
import React from 'react';
import TopNavBarChangeLanguage from '../TopNavBarChangeLanguage';
import LandingTopNavButton from '@/js/modules/landing/components/LandingTopNavButton';
import userStore from '@/js/modules/users/store/v2/UserStore';
import { Link, useLocation } from 'react-router-dom';
import { PageRoutes } from '@/utils/PageRoutes';
import { useQuery } from '@tanstack/react-query';
import Icon from '@/js/components/Icon/Icon';

interface ITopBarMobileMenu extends React.PropsWithChildren {
	menuItems: {
		to: string;
		name: string;
		translation: string;
	}[];
	dropdownItems: {
		to: string;
		name: string;
		translation: string;
		icon: Icons;
	}[];
	actionButton: React.ReactNode;
	isMobileMenuOpen: boolean;
	closeMenu: () => void;
}

const TopBarMobileMenu = (props: ITopBarMobileMenu) => {
	const [user, me] = useUserStore((state) => [state.user, state.me], shallow);
	const { pathname } = useLocation();

	useQuery({
		queryKey: ['navbarMobileMenu'],
		queryFn: async () => await me(),
	});

	return (
		<>
			{props.isMobileMenuOpen && (
				<div
					onClick={props.closeMenu}
					className="fixed inset-0 bg-black bg-opacity-50 z-900"
					style={{ zIndex: 999 }}
				/>
			)}
			<div
				className={`fixed top-0 right-0 w-[280px] h-full bg-white transition-all duration-300 ease-in-out transform rounded-xl shadow ring-1 ring-black ring-opacity-5 ${
					props.isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
				style={{ zIndex: 1000 }}
			>
				<div className="absolute top-2 right-2">
					<button
						type={'button'}
						className={
							'inline-flex items-center justify-center p-2 text-shade-black bg-neutral-50 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300'
						}
						onClick={props.closeMenu}
						aria-controls={'mobile-menu'}
						aria-expanded={props.isMobileMenuOpen ? 'true' : 'false'}
					>
						<div className={'block text-shade-black'}>
							<Icon name={Icons.Close} />
						</div>
					</button>
				</div>
				<div className="pt-10 text-shade-black font-semibold text-base">
					<TopBarMobileMenuNavigation items={props.menuItems} />
				</div>
				<div className={'pb-3 border-t border-gray-200'}>
					<div className={'flex justify-center flex-col px-4 mb-5'}>
						{pathname.includes(PageRoutes.LandingForCommunities) && props.actionButton}
						{!pathname.includes(PageRoutes.LandingForCommunities) && <LandingTopNavButton />}
					</div>
					{user.id ? (
						<>
							<Link to={PageRoutes.MyProfilePage} className='hover:bg-gray-50 cursor-pointer'>
								<div className={'flex items-center px-4 sm:px-6 hover:bg-gray-50'}>
									<div className={'flex-shrink-0'}>
										<Avatar
											width={'w-11'}
											height={'h-11'}
											isRounded={true}
											isLazyLoaded={true}
											attrs={{
												src: user.avatarUrl,
											}}
										/>
									</div>
									<span className={'w-screen break-all'}>
										<TopBarUserInfo />{' '}
									</span>
								</div>
							</Link>
							<TopBarMobileMenuDropdown items={props.dropdownItems} />
						</>
					) : (
						<div className="px-4 w-36 md:w-full">
							<TopNavBarChangeLanguage />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default TopBarMobileMenu;

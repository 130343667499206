import BaseStore from "@/js/core/BaseStore";
import { ICompanyStore } from "@/js/modules/company/store/CompanyTypes";
import CompanyService from "@/js/modules/company/services/CompanyService";
import sectorStore from "../../sectors/store/SectorStore";
import locationStore from "../../locations/store/LocationStore";
import locationTypeStore from "../../locationTypes/store/LocationTypeStore";
import employeeCountStore from "../../employeeCounts/store/EmployeeCountStore";
import { errorHandler } from "@/js/helpers/errorHandler";
import socialAccountStore from "../../socialMedias/store/SocialAccountStore";
import { SocialAccountNames } from "../../socialMedias/store/SocialAccountTypes";
import userStore from "../../users/store/v2/UserStore";
import { RowStates } from "@/js/core/enums/RowStates";

const companyStore = {
	company: {
		id: ''
	},
	form: {
		title: '',
		description: '',
		foundingDate: '',
		logoUrl: '',
		coverUrl: '',
		locationId: '',
		sectorId: '',
		socialAccounts: [],
		locations: [],
		locationTypeId: '',
		employeeCountId: '',
		hasEmployerAgreement: false,
		position: '',
		interests: [],
		gsmNumber: '', // user table field
		gradientColors: [],
		companyPhone: '',
		companyEmail: '',
	}
}

class CompaniesStore extends BaseStore<ICompanyStore, CompanyService> {
	initializeStore(state?) {
		return this.useStore(state ? state : undefined);
	}

	getCreatePayload(payload) {
		delete payload.logoUrl
		delete payload.coverUrl
		return payload
	}

	setCreatePayload(payload) {
		this.state.form = {
			...this.state.form,
			...payload
		}
	}

	clearPayload() {
		this.state.form = {}
	}

	async fetchWithRelations(id: string) {
		const { data } = await this.service.fetchWithRelations(id);
		this.state.companies = data.result;

		return data.result;
	}

	async fetchCompany() {
		const { data } = await this.service.fetchCompany();
		this.state.company = data.result;

		return data.result;
	}

	async createCompany() {
		this.state.isSaving = true;
		try {
			return await this.service.createCompany(this.getPayload());
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async createForEvent(slug: string) {
		try {
			this.state.isSaving = true;

			const user = await userStore.fetchMe();

			const payload = {
				slug,
				userId: user.id,
				title: this.state.form.title,
				sectorId: this.state.form.sectorId,
				hasEmployerAgreement: this.state.form.hasEmployerAgreement,
				position: this.state.form.position,
				gsmNumber: this.state.form.gsmNumber,
			}

			const result = await this.service.createForEvent(payload);

			return result;
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateCompany(id: string) {
		this.state.isSaving = true;

		try {
			return await this.service.updateCompany(id, this.getPayload());
		} catch (error) {
			const errorMessages = errorHandler(error);

			if (Object.keys(errorMessages!).length) {
				this.state.errors = errorMessages;
			}
		} finally {
			this.state.isSaving = false;
		}
	}

	async updateImportedCompanyData(status: RowStates): Promise<boolean | undefined> {
		this.state.isLoading = true;
		try {
			const company = await this.getCompanyByAuthUser();
			const response = await this.service.updateImportedCompanyData(company?.id, { 'state' : status });
			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	async fetchForCompany(id: string | undefined) {
		this.state.isLoading = true;

		try {
			await Promise.all([
				sectorStore.fetchSectors(),
				locationStore.fetchLocations(),
				locationTypeStore.fetchLocationTypes(),
				employeeCountStore.fetchEmployeeCount(),
				socialAccountStore.fetchSocialAccounts(),
			]);

			if (id) {
				const response = await this.service.fetchWithRelations(id);
				this.setFormData(response.data.result);
			}
		} catch (error) {
			console.log(error)
			this.goToNotFound()
		} finally {
			this.state.isLoading = false;
		}
	}

	setFormData(data: any) {
		this.state.form = {
			...data,
			sectorId: data.sectors[0]?.id,
			locationId: data.locations[0]?.id,
			locationTypeId: data.locationType?.id,
			employeeCountId: data.employeeCount?.id,
			foundingDate: String(data?.foundingDate)
		}
	}

	async getCompanyByAuthUser() {
		const response = await this.service.getCompanyByAuthUser();
		return response.data.result;
	}

	async checkIfCompanyCanCreateJobPost(): Promise<boolean | undefined> {
		this.state.isLoading = true;
		try {
			const company = await this.getCompanyByAuthUser();
			const response = await this.service.checkIfCompanyCanCreateJobPost(company?.id);

			return response.data.result;
		} catch (error) {
			console.log(error);
		} finally {
			this.state.isLoading = false;
		}
	}

	getPayload() {
		return {
			...this.state.form,
			foundingDate: +this.state.form.foundingDate || ''
		}
	}

	getSocialMediaProviders() {
		const providersToExclude = [
			SocialAccountNames.Discord,
			SocialAccountNames.Github,
			SocialAccountNames.Dribbble,
			SocialAccountNames.Gitlab,
			SocialAccountNames.Behance,
			SocialAccountNames.Reddit,
			SocialAccountNames.Slack,
			SocialAccountNames.Spotify,
			SocialAccountNames.Telegram,
			SocialAccountNames.Twitch,
			SocialAccountNames.Whatsapp,
		]

		return socialAccountStore.getFilteredSocialAccountProviders(providersToExclude);
	}

	getEmployeeCounts() {
		const employeeCounts = employeeCountStore.state.employeeCounts;
		return this.optionsMapper(employeeCounts);
	}

	getLocations() {
		const locations = locationStore.state.locations;
		return this.convertToOptionsObjectForLocations(locations);
	}

	getSectors() {
		const sectors = sectorStore.state.sectors;
		return this.optionsMapper(sectors);
	}

	getLocationTypes() {
		const locationTypes = locationTypeStore.state.locationTypes;
		return this.optionsMapper(locationTypes);
	}

	createYears() {
		const years: { label: string, value: string }[] = []

		for (let i = new Date().getFullYear(); i >= 1700; i--) {
			years.push({ label: i.toString(), value: i.toString() })
		}

		return years
	}

	optionsMapper(options) {
		return options.map(option => {
			return { label: option.name, value: option.id };
		});
	}

	convertToOptionsObjectForLocations(data: any) {
		return data.map(item => {
			if (item.name === item.countryName) {
				return { value: item.id, label: item.name };
			}

			return { value: item.id, label: `${item.name}, ${item.countryName}` };
		})
	}

	public resetSocialMediaErrors() {
		const errors = {};
		Object.keys(this.state.errors).map((key) => {
			if (!key.includes('url')) {
				errors[key] = this.state.errors[key]
			}
		})

		this.state.errors = errors;
	}
}

export default new CompaniesStore(companyStore, CompanyService);

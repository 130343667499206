import { useToggle } from '@/js/hooks/useToggle/useToggle'
import TopBarDropdownMenu from '../dropdown/TopNavBarDropdownMenu'
import React from 'react'
import { Icons } from '@/utils/Icons'
import Avatar from '@/js/components/Avatar/Avatar'
import useUserStore from "@/js/modules/users/store/useUserStore";
import { useQuery } from '@tanstack/react-query'
import MessageIcon from '@/assets/icons/MessageIcon'
import { useNavigate } from 'react-router-dom'
import { PageRoutes } from '@/utils/PageRoutes'
import conversationStore from '@/js/modules/chats/store/ConversationStore/ConversationStore';
import BellIconV3 from '@/assets/icons/BellIconV3'
import NotificationModal from '../notifications/NotificationModal'
import { useOnClickOutside } from '../TopNavBar'

interface ITopBarProfile extends React.PropsWithChildren {
	hasMessages?: number;
	hasNotifications?: number;
	dropdownItems?: {
		to: string,
		name: string,
		translation: string,
		icon: Icons,
		itemClasses?: string
		type?: string;
		children?: {
			to: string;
			name: string;
			translation: string;
		}[];
	}[]
	handleOpenNotifications?: () => void;
}

const Author = (props: ITopBarProfile) => {
	conversationStore.initializeStore();

	const navigate = useNavigate();
	const [user, me] = useUserStore(state => [state.user, state.me])

	const { dropdownItems = [] } = props

	const ref = React.useRef<HTMLDivElement>(null)
	const [moreActionsRef, setMoreActionsRef] = React.useState<React.RefObject<HTMLButtonElement> | null>(null)
	
	useOnClickOutside(
		ref,
		() => {
			setIsDropdownOpen(false)
			setIsNotificationsOpen(false)
		},
		moreActionsRef ? [moreActionsRef] : []
	)

	const [isDropdownOpen, toggleIsDropdownOpen, setIsDropdownOpen] = useToggle(false)
	const [isNotificationsOpen, toggleIsNotificationsOpen, setIsNotificationsOpen] = useToggle(false)

	const handleOpenMenu = () => {
		if (isNotificationsOpen) {
			setIsNotificationsOpen(false)
		}
		toggleIsDropdownOpen()
	}

	const handleOpenNotifications = () => {
		if (isDropdownOpen) {
			setIsDropdownOpen(false)
		}
		toggleIsNotificationsOpen()
	};

	useQuery({
		queryKey: ['navbarAuthor', user.id],
		queryFn: async () => await me(),
		enabled: Boolean(user?.id),
	})

	return (
		<div ref={ref} className={"relative flex items-center justify-between h-full border-l border-gray-100 pl-7 gap-3"}>
			<div className='flex'>
				<button onClick={() => navigate(PageRoutes.MessagesPage)} type={"button"}>
					<MessageIcon hasMessages={props.hasMessages} />
				</button>
				<button onClick={handleOpenNotifications} type={"button"}>
					<BellIconV3 hasNotifications={props.hasNotifications} />
				</button>
			</div>
			<button onClick={handleOpenMenu} type={"button"} className={"flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"}>
				<Avatar
					isRounded={true}
					isLazyLoaded={true}
					width={'w-11'}
					height={'h-11'}
					attrs={{ src: user.avatarUrl }}
				/>
			</button>
			<div className={"absolute right-0 top-20 z-20"}>
				{isDropdownOpen && <TopBarDropdownMenu items={dropdownItems} isDropdownOpen={isDropdownOpen} />}
			</div>
			<div className={"absolute right-10 top-20 z-20"}>
				{isNotificationsOpen && <NotificationModal isDropdownOpen={isNotificationsOpen} setMoreActionsRef={setMoreActionsRef}/>}
			</div>
		</div>
	)
}

export default Author

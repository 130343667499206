import React from 'react'
import { Navigate, Route } from "react-router-dom";
import AuthGuard from "../Guards/AuthGuard";
import { PageRoutes } from '@/utils/PageRoutes';
import AuthLayoutForPublic from '@/js/layouts/auth/AuthLayoutForPublic';
import DashboardLayout from '@/js/layouts/dashboard/DashboardLayout';
import OnboardingLayout from '@/js/layouts/onboarding/OnboardingLayout';
import CompanyLayout from '@/js/layouts/onboarding/CompanyLayout';
import BlokOwnerLayout from '@/js/layouts/onboarding/BlokOwnerLayout';

const CreatePostPage = React.lazy(() => import('@/js/modules/posts/pages/CreatePostPage'));
const BlokJobPostRewardsPage = React.lazy(() => import('@/js/modules/bloks/pages/BlokJobPostRewardsPage'));
const ManageBlokPage = React.lazy(() => import('@/js/modules/settings/pages/ManageBlok/ManageBlokPage'));
const BuySubscriptionPlanPage = React.lazy(() => import('@/js/modules/settings/pages/BuySubscriptionPlanPage'));
const SubscriptionPaymentSuccessPage = React.lazy(() => import('@/js/modules/subscriptions/pages/SubscriptionPaymentSuccessPage'));
const MessagesPage = React.lazy(() => import('@/js/modules/chats/pages/MessagesPage'));
const CompleteRegisterationPage = React.lazy(() => import('@/js/modules/auth/pages/CompleteRegisterationPage'));
const CompleteCompanyRegisterationPage = React.lazy(() => import('@/js/modules/auth/pages/CompleteCompanyRegisterationPage'));
const CreateJobDetails = React.lazy(() => import('@/js/modules/jobs/pages/CreateJobDetails'));
const MyCompanyPage = React.lazy(() => import('@/js/modules/company/pages/MyCompanyPage'));
const UpdateCompany = React.lazy(() => import('@/js/modules/onboarding/components/company/CreateCompany'));
const PreviewJobPage = React.lazy(() => import('@/js/modules/jobs/pages/PreviewJobPage'));
const OnboardingPage = React.lazy(() => import('@/js/modules/onboarding/pages/OnboardingPage'));
const PublishJobStepsPage = React.lazy(() => import('@/js/modules/jobs/pages/PublishJobStepsPage'));
const CreateJobSummary = React.lazy(() => import('@/js/modules/jobs/pages/CreateJobSummary'));
const TalentEditGeneral = React.lazy(() => import('@/js/modules/talent/pages/TalentEditGeneral'));
const TalentEditWorkPreferences = React.lazy(() => import('@/js/modules/talent/pages/TalentEditWorkPreferences'));
const TalentProfilePage = React.lazy(() => import('@/js/modules/talent/pages/TalentProfilePage/TalentProfilePage'));
const CreateBlokOwnerStep = React.lazy(() => import('@/js/modules/bloks/pages/CreateBlokOwnerStep'));
const CreateBlokStep = React.lazy(() => import('@/js/modules/bloks/pages/CreateBlokStep'));
const BlokVerifyPage = React.lazy(() => import('@/js/modules/bloks/pages/BlokVerifyPage'));
const RewardJobsPage = React.lazy(() => import('@/js/modules/jobs/pages/RewardJobsPage'));
const BlokJobsPage = React.lazy(() => import('@/js/modules/jobs/pages/BlokJobsPage'));
const CreateJobSelectionPage = React.lazy(() => import('@/js/modules/jobs/pages/CreateJobSelectionPage'));
const JobVerificationPage = React.lazy(() => import('@/js/modules/jobs/pages/JobVerificationPage'));
const JobSelectBlokPage = React.lazy(() => import('@/js/modules/jobs/pages/JobSelectBlokPage'));
const JobApplicationStepPage = React.lazy(() => import('@/js/modules/jobs/pages/JobApplicationStepPage'));
const JobPostApplicationsPage = React.lazy(() => import('@/js/modules/jobs/pages/JobPostApplicationsPage'));
const JoinedBloksPage = React.lazy(() => import('@/js/modules/bloks/pages/JoinedBloksPage'));
const MyBlokPage = React.lazy(() => import('@/js/modules/bloks/pages/MyBlokPage'));
const MyBloksPage = React.lazy(() => import('@/js/modules/bloks/pages/MyBloksPage'));
const MyProfilePage = React.lazy(() => import('@/js/modules/talent/pages/MyProfilePage'));
const UserFeedPage = React.lazy(() => import('@/js/modules/talent/pages/UserFeedPage'));
const MailActivationPage = React.lazy(() => import('@/js/modules/auth/pages/MailActivationPage'));
const DidNotGetMailPage = React.lazy(() => import('@/js/modules/auth/pages/DidNotGetMailPage'));
const MailActivationTimeoutPage = React.lazy(() => import('@/js/modules/auth/pages/MailActivationTimeoutPage'));
const MailActivationFailure = React.lazy(() => import('@/js/modules/auth/pages/MailActivationFailure'));
const PaymentSuccessPage = React.lazy(() => import('@/js/modules/payments/pages/PaymentSuccessPage'));
const PaymentPage = React.lazy(() => import('@/js/modules/jobs/pages/PaymentPage'));
const DynamicPaymentPage = React.lazy(() => import('@/js/modules/payments/pages/DynamicPaymentPage'));
const PaymentFailedPage = React.lazy(() => import('@/js/modules/payments/pages/PaymentFailedPage'));
const MemberPaymentHistoryPage = React.lazy(() => import('@/js/modules/payments/pages/MemberPaymentHistoryPage'));
const BlokServiceCreatePage = React.lazy(() => import('@/js/modules/bloks/pages/BlokServiceCreatePage'));
const BlokAdvertCreatePage = React.lazy(() => import('@/js/modules/bloks/pages/BlokAdvertCreatePage'));
const EventCheckInPage = React.lazy(() => import('@/js/modules/events/pages/EventCheckInPage'));
const EventViewTicketPage = React.lazy(() => import('@/js/modules/events/pages/EventViewTicketPage'));

const AuthRoutes = (
	<Route element={<AuthGuard />}>
		<Route element={<AuthLayoutForPublic />}>
			<Route path={PageRoutes.SignUpMailActivation} element={<MailActivationPage />} />
			<Route path={PageRoutes.DidNotReceiveActivationMail} element={<DidNotGetMailPage />} />
			<Route path={PageRoutes.MailActivationTimeout} element={<MailActivationTimeoutPage />} />
			<Route path={PageRoutes.MailActivationFailure} element={<MailActivationFailure />} />
			<Route path={PageRoutes.CompleteRegistration} element={<CompleteRegisterationPage />} />
			<Route path={PageRoutes.CompleteCompanyRegistration} element={<CompleteCompanyRegisterationPage />} />
		</Route>

		<Route element={<OnboardingLayout />}>
			<Route path={PageRoutes.Onboarding} index element={<OnboardingPage />} />
		</Route>

		<Route path={PageRoutes.Dashboard} element={<DashboardLayout />}>

			<Route index element={<Navigate to={{ pathname: PageRoutes.UserFeedPage, search: window.location.search }} />} />

			<Route element={<CompanyLayout />}>
				<Route path={PageRoutes.CompanyCreate} index element={<OnboardingPage />} />
			</Route>

			<Route element={<BlokOwnerLayout />}>
				<Route path={PageRoutes.BlokCreate} index element={<OnboardingPage />} />
			</Route>

			<Route path={PageRoutes.CompanyEdit} element={<UpdateCompany />} />
			<Route path={PageRoutes.MyCompanyPage} element={<MyCompanyPage />} />
			{/* job post */}
			<Route path={PageRoutes.JobCheckoutStep} element={<PaymentPage />} />
			<Route path={PageRoutes.DashboardRewardJobs} element={<RewardJobsPage />} />
			<Route path={PageRoutes.DashboardBlokJobs} element={<BlokJobsPage />} />
			<Route path={PageRoutes.CreateJobSelectionPage} element={<CreateJobSelectionPage />} />
			<Route path={PageRoutes.EditJobSelectBlokStep} element={<JobSelectBlokPage />} />
			<Route path={PageRoutes.JobSummaryStep} element={<CreateJobSummary />} />
			<Route path={PageRoutes.EditJobSummaryStep} element={<CreateJobSummary />} />
			<Route path={PageRoutes.JobDetailsStep} element={<CreateJobDetails />} />
			<Route path={PageRoutes.JobsPreviewStep} element={<PreviewJobPage />} />
			<Route path={PageRoutes.JobApplicationStep} element={<JobApplicationStepPage />} />
			<Route path={PageRoutes.JobsPublishStep} element={<PublishJobStepsPage />} />
			<Route path={PageRoutes.JobVerificationStep} element={<JobVerificationPage />} />
			<Route path={PageRoutes.JobPostApplications} element={<JobPostApplicationsPage />} />
			{/* talent */}
			<Route path={PageRoutes.TalentCreate} element={<TalentEditGeneral />} />
			<Route path={PageRoutes.TalentUpdate} element={<TalentEditGeneral />} />
			<Route path={PageRoutes.TalentUpdateDetails} element={<TalentEditWorkPreferences />} />
			<Route path={PageRoutes.TalentProfilePage} element={<TalentProfilePage />} />
			<Route path={PageRoutes.MyProfilePage} element={<MyProfilePage />} />
			<Route path={PageRoutes.UserFeedPage} element={<UserFeedPage />} />
			{/*  Bloks */}
			<Route path={PageRoutes.MyBlokPage} element={<MyBlokPage />} />
			<Route path={PageRoutes.MyBloksPage} element={<MyBloksPage />} />
			<Route path={PageRoutes.JoinedBloks} element={<JoinedBloksPage />} />
			<Route path={PageRoutes.BlokOwnerCreate} element={<CreateBlokOwnerStep />} />
			<Route path={PageRoutes.BlokOwnerEdit} element={<CreateBlokOwnerStep />} />
			<Route path={PageRoutes.BlokCreate} element={<CreateBlokStep />} />
			<Route path={PageRoutes.BlokEdit} element={<CreateBlokStep />} />
			<Route path={PageRoutes.BlokVerify} element={<BlokVerifyPage />} />
			<Route path={PageRoutes.BlokJobPostRewards} element={<BlokJobPostRewardsPage />} />
			<Route path={PageRoutes.BlokServiceCreate} element={<BlokServiceCreatePage />} />
			<Route path={PageRoutes.BlokAdvertCreate} element={<BlokAdvertCreatePage />} />

			{/* event */}
			<Route path={PageRoutes.EventCheckInPage} element={<EventCheckInPage />} />
			<Route path={PageRoutes.EventViewTicketPage} element={<EventViewTicketPage />} />

			{/* Payment */}
			<Route path={PageRoutes.MemberPaymentHistory} element={<MemberPaymentHistoryPage />} />
			<Route path={PageRoutes.Payment} element={<DynamicPaymentPage />} />
			<Route path={PageRoutes.PaymentSuccess} element={<PaymentSuccessPage />} />
			<Route path={PageRoutes.PaymentFailed} element={<PaymentFailedPage />} />
			{/* Post */}
			<Route path={PageRoutes.CreatePostPage} element={<CreatePostPage />} />
			<Route path={PageRoutes.EditPostPage} element={<CreatePostPage />} />
			{/* Chat */}
			<Route path={PageRoutes.MessagesPage} element={<MessagesPage />} />

			{/* Settings */}
			<Route path={PageRoutes.ManageBlokPage} element={<ManageBlokPage />} />
			<Route path={PageRoutes.BuySubscriptionPlanPage} element={<BuySubscriptionPlanPage />} />
			<Route path={PageRoutes.SubscriptionPaymentSuccessPage} element={<SubscriptionPaymentSuccessPage />} />
		</Route>
	</Route>
)

export default AuthRoutes

import StarIconV3 from '@/assets/icons/StarIconV3/StarIconV3';
import React from 'react';

interface PointsBadgeProps {
	points: number;
	className?: string;
	size?: 'small' | 'medium' | 'large';
	translationKey: string;
	height?: string;
}

const PointsBadge: React.FC<PointsBadgeProps> = ({
	points,
	className = '',
	size = 'medium',
	translationKey,
	height,
}) => {
	const iconSize = {
		small: 12,
		medium: 14,
		large: 21,
	}[size];

	const textSize = {
		small: 'text-[10px]',
		medium: 'text-[11px]',
		large: 'text-base',
	}[size];

	return (
		<div
			className={`flex gap-1 items-center justify-center max-w-max px-2 bg-neutral-50 text-shade-black text-sm font-semibold py-[2px] rounded-full shadow-md ${className}`}
			style={{ height }}
		>
			<StarIconV3 size={iconSize} />
			<span className={`${textSize} font-semibold font-sans`}>
				{translationKey.replace('{{count}}', String(points))}
			</span>
		</div>
	);
};

export default PointsBadge;

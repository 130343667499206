import BaseStore from "@/js/core/BaseStore";
import { ITalentProfileStore } from "@/js/modules/talent/store/TalentTypes";
import TalentService from "@/js/modules/talent/services/TalentService";
import { AccountTypes, PriceSymbols } from '@/utils/Enums';
import userStore from "@/js/modules/users/store/v2/UserStore";
import storyStore from '@/js/modules/stories/store/StoryStore';

const talentProfileStore = {
  talent: {},
}

class TalentProfileStore extends BaseStore<ITalentProfileStore, TalentService> {
  initializeStore() {
    return this.useStore();
  }

	async fetchTalent(id) {
		try {
			this.state.isLoading = true;
			const response = await this.service.fetchTalent(id);
			await storyStore.fetchByEntityType(response.data.result.id, AccountTypes.Talent);

			this.state.talent = response.data.result;
			return response.data.result;
		} catch (error) {
			const response = await this.service.fetchTalentForVisitorPage(id);
			this.state.talent = response.data.result;
			return response.data.result;
		} finally {
			this.state.isLoading = false;
		}
	}

  getPositionText(position, lastWorkedCompany) {
    if (!position || !position.name) {
      return '';
    } else if (lastWorkedCompany) {
      return `${position.name} @${lastWorkedCompany}`
    }

    return position.name;
  }

  async updateAvatarImage(newAvatarUrl: string) {
    this.state.talent.user.avatarUrl = newAvatarUrl

    await this.service.updateAvatarImage(this.state.talent.user.id, {
      ...this.state.talent,
      avatarUrl: newAvatarUrl
    });
  }

  setCoverPhotoUrl(url: string) {
		this.state.coverPhotoUrl = url;
		this.state.talent.coverPhotoUrl = url;
	}

  async uploadCoverPhoto(id: string) {
		await this.service.uploadCoverPhoto(id, {
			coverPhotoUrl: this.state.coverPhotoUrl
		})
	}

  getMajor(lastEducationName, lastAcademicMajor) {
    if (!lastEducationName && !lastAcademicMajor) {
      return '';
    } else if (lastEducationName && lastAcademicMajor) {
      return `${lastEducationName} @${lastAcademicMajor}`;
    }

    return lastEducationName || lastAcademicMajor;
  }

  getSalary(salary) {
    return `${PriceSymbols.USD} ${new Intl.NumberFormat().format(salary)}`.replace(',', '.');
  }

  isCompanyAccountType() {
    return userStore.isCompanyOwnerAccountType()
  }

  isBlokOwnerAccountType() {
    return userStore.isBlokOwnerAccountType();
  }

	isVisitor() {
    return userStore.isVisitor()
  }

  isOwner() {
    return this.state.talent.isOwner;
  }
}

export default new TalentProfileStore(talentProfileStore, TalentService) as TalentProfileStore;

import React from "react";
import { tailwindColors } from "@/plugins/tailwind";
import { Line as ProgressBar } from "rc-progress";
import LocalStorage from "@/js/helpers/localStorage";
import { OnboardingSteps, talentOnboardingSteps } from "../constants/onbardingConstants";

interface IOnboardingProgresBar { }

export default function OnboardingProgresBar(props: IOnboardingProgresBar) {
   const steps = LocalStorage.get('onboardingSteps') as typeof talentOnboardingSteps
   const stepName = LocalStorage.get('lastOnboardingStep') as OnboardingSteps

   if (!!steps.length && stepName) {
      const currentStepIndex = steps.findIndex(step => step === stepName)

      const percent = (currentStepIndex ) / (steps.length-1) * 100

      return (
         <ProgressBar
            strokeLinecap={"butt"}
            className={'h-[6px] w-full'}
            trailColor={tailwindColors['neutral']['50']}
            strokeColor={tailwindColors['primary']['500']}
            percent={stepName === OnboardingSteps.CompanyInterest || stepName === OnboardingSteps.BlokOwnerInterests ? 0 : percent}
         />
      )
   }

   return <React.Fragment />
}

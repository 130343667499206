import React from 'react';
import { __ } from '@/utils/Trans';

interface IReadMore {
   limit: number;
   children: React.ReactNode | string;
   isTalentProfile?: boolean;
   isHtmlString?: boolean;
}

const ReadMore = ({ limit, children, isTalentProfile = false, isHtmlString = false }: IReadMore) => {
   const [isReadMore, setIsReadMore] = React.useState<boolean>(false);

   const extractTextFromHtml = (htmlString: string) => {
      const doc = new DOMParser().parseFromString(htmlString, 'text/html');
      return doc.body.textContent || '';
   };

   const textToDisplay = isHtmlString ? extractTextFromHtml(String(children)) : String(children);

   const handleToggleClick = () => setIsReadMore(!isReadMore);

   return (
      <>
         {isReadMore ? (
            <span className={"whitespace-break-spaces"}>{children}</span>
         ) : (
            <span className={`whitespace-break-spaces ${isTalentProfile ? "limited-two-line-block" : ""}`}>
               {textToDisplay.slice(0, limit) + '...'}
            </span>
         )}
         <button onClick={handleToggleClick} className={'pl-1 text-primary-500 font-semibold text-sm'}>
            {isReadMore ? __("ReadMore.ReadLess") : __("ReadMore.ReadMore")}
         </button>
      </>
   );
}

export default ReadMore;

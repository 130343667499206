import BaseService from "@/js/core/BaseService";
import { CommentEndpoints } from "./CommentEndpoints";

export default class CommentService extends BaseService {

	async createComment(payload) {
		return await this.api().post(CommentEndpoints.Create, payload);
	}

	async fetchAllCommentBy(ownerId) {
		return await this.api().get(CommentEndpoints.GetAll.replace(':ownerId', ownerId));
	}

	async fetchAllCommentByForumId(ownerId) {
		return await this.api().get(CommentEndpoints.GetAllForForum.replace(':ownerId', ownerId));
	}


	async deleteComment(ownerId, id) {
		return await this.api().delete(CommentEndpoints.Delete.replace(':ownerId', ownerId).replace(':id', id));
	}

	async updateComment(ownerId, payload) {
		return await this.api().put(CommentEndpoints.Update.replace(':ownerId', ownerId), payload);
	}

}

import Icon from '@/js/components/Icon/Icon'
import { useLogout } from '@/js/hooks/useLogout/useLogout'
import { Icons } from '@/utils/Icons'
import { __ } from '@/utils/Trans'

const LogOutButton = () => {
    const handleLogout = useLogout()

  const logout = () => {
    handleLogout();
  }

    return (
        <button onClick={logout} type={"button"} className={"flex hover:bg-gray-50 px-4 py-2 gap-3 w-full text-sm text-shade-black hover:text-primary-500"}>
            <Icon name={Icons.Logout} />
            {__("TopBar.Logout")}
        </button>
    )
}

export default LogOutButton

import { Icons } from '@/utils/Icons';
import { Transition } from '@headlessui/react';
import React from 'react';
import TopBarDropdownMenuItem from './TopNavBarDropdownMenuItem';
import AuthorInfo from '../author/AuthorInfo';
import LogOutButton from '../author/LogOutButton';
import TopNavBarChangeLanguage from '@/js/layouts/topbar/TopNavBarChangeLanguage';
import { ScreenModes } from '@/utils/Enums';
import { Link } from 'react-router-dom';
import { __ } from '@/utils/Trans';
import { PageRoutes } from '@/utils/PageRoutes';

interface ITopBarDropdownMenu extends React.PropsWithChildren {
	isDropdownOpen: boolean;
	items: {
		to: string;
		name: string;
		translation: string;
		icon: Icons;
		itemClasses?: string;
		type?: string;
		children?: {
			to: string;
			name: string;
			translation: string;
		}[];
	}[];
}

const TopBarDropdownMenu = (props: ITopBarDropdownMenu) => {
	const [openSubmenu, setOpenSubmenu] = React.useState<string | null>(null);

	const toggleSubmenu = (itemName: string) => {
		setOpenSubmenu(openSubmenu === itemName ? null : itemName);
	};

	return (
		<Transition
			appear
			show={props.isDropdownOpen}
			as={React.Fragment}
			enter={'transition ease-out duration-100'}
			enterFrom={'transform opacity-0 scale-95'}
			enterTo={'transform opacity-100 scale-100'}
			leave={'transition ease-in duration-75'}
			leaveFrom={'transform opacity-100 scale-100'}
			leaveTo={'transform opacity-0 scale-95'}
		>
			<div
				tabIndex={-1}
				className={
					'relative w-[13rem] py-1 mt-2 z-10 origin-top-right bg-white rounded-md shadow ring-1 ring-black ring-opacity-5 focus:outline-none'
				}
			>
				<Link to={PageRoutes.MyProfilePage} className="hover:bg-gray-50 cursor-pointer">
					<AuthorInfo classes={'py-3 hover:bg-gray-50'} />
				</Link>
				<div>
					{props.items.map((item) => (
						<div key={item.name}>
							<TopBarDropdownMenuItem
								item={item}
								classes={`flex flex-row-reverse justify-end px-4 py-2 text-sm text-shade-black gap-3 hover:bg-gray-50 hover:text-primary-500 ${item.itemClasses}`}
								isSubmenuOpen={openSubmenu === item.name}
								toggleSubmenu={() => toggleSubmenu(item.name)}
							/>

							{/* Render submenu here in the parent */}
							{item.children && openSubmenu === item.name && (
								<Transition
									appear
									show={openSubmenu === item.name}
									as={React.Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<div className="space-y-2">
										<div className="w-full">
											{item.children.map((child) => (
												<Link
													key={child.name}
													to={child.to}
													className="block pl-[50px] px-4 py-2 text-sm text-shade-black hover:text-primary-500 hover:bg-gray-50"
												>
													<span className="line-clamp-2 overflow-hidden">{child.name}</span>
												</Link>
											))}
										</div>
									</div>
								</Transition>
							)}
						</div>
					))}
				</div>
				<LogOutButton />
				<div className={'py-2 w-full flex items-center justify-center'}>
					<div className={'-ml-5'}>
						<TopNavBarChangeLanguage />
					</div>
				</div>
			</div>
		</Transition>
	);
};
export default TopBarDropdownMenu;

import BaseService from "@/js/core/BaseService";
import { ConversationEndpoints } from "../../store/ConversationStore/ConversationEndpoints";
import { ConversationFilterTypes } from "../../components/ConversationFilter";

export default class ConversationService extends BaseService {
   async fetchConversationsByUser(filter: ConversationFilterTypes, accountType: string) {
      return await this.api().get(ConversationEndpoints.FetchThreadsByUser.replace(':filter', filter).replace(':accountType', accountType))
   }
   async fetchExistingConversationBetweenTwoUsers(receiverId: string, receiverType: string, senderAccountType: string) {
      return await this.api().get(ConversationEndpoints.FetchExistingBetweenTwoUsers.replace(':receiverId', receiverId).replace(':receiverAccountType', receiverType).replace(':senderAccountType', senderAccountType))
   }
   async updateArchivedDate(threadId: string) {
      return await this.api().put(ConversationEndpoints.UpdateArchivedDate.replace(':threadId', threadId))
   }
   async updateConversationLastMessageReadDate(threadId: string) {
      return await this.api().put(ConversationEndpoints.UpdateLastMessageReadDate.replace(':threadId', threadId))
   }
   async fetchForNotifications() {
      return await this.api().get(ConversationEndpoints.FetchForNotifications)
   }
}

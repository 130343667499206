import { useOutletContext } from "react-router-dom";
import OnboardingBottom from "../OnboardingBottom";
import { OnboardingOutletContextType } from "../../constants/onbardingConstants";
import SelectBox from "@/js/components/SelectBox/SelectBox";
import blokStore from "@/js/modules/bloks/store/BlokStore";
import React from 'react'
import skillStore from "@/js/modules/skills/store/SkillStore";
import { __ } from "@/utils/Trans";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

export default function OnboardingBlokOwnerSkills() {
   const [newSkillIds, setNewSkillIds] = React.useState<string[]>([]);

   const { handleChangeStep } = useOutletContext<OnboardingOutletContextType>()

   const handleContinueClick = () => {
      handleChangeStep()
   }

   const handleOnChangeSkills = (skills: { label: string, value: string, __isNew__?: boolean }[]) => {
			if (skills.length > 5) {
				toast.error(__('Validations.SelectMaxFiveSize'))
				return
			}

      const newSkillList = skills.filter(skill => skill.__isNew__);
      const newSkillIdList = [...newSkillIds, ...newSkillList.map(skill => skill.value)]

      const newSkills = skills.map(skill => ({
         id: skill.value,
         name: skill.label,
         isNew: newSkillIdList.includes(skill.value)
      }));

      const selecedBlokSkills = skills.map(skill => ({
         ...skill,
         __isNew__: newSkillIdList.includes(skill.value)
      }))

      setNewSkillIds(newSkillIdList);

      const skillIds = newSkills.map((skill: { id: string }) => skill.id);
      const list = skillStore.state.skills.filter(skill => !skillIds.includes(skill.id));

      skillStore.state.skills = [...list, ...newSkills]

      blokStore.set("skills", selecedBlokSkills);
   };

   useQuery({
      queryKey: ['skills'],
      queryFn: async () => {
         await skillStore.fetchSkills()
         return true
      }
   })

   const skills = blokStore.skills()

   return (
      <div className="flex flex-col justify-center items-center">
         <div className={"onboarding-full-height py-10 px-4 flex flex-col justify-start items-center gap-7 max-w-2xl w-full xl:gap-10 xl:p-0 xl:justify-center"}>
            <div className={"flex flex-col gap-4"}>
               <h1 className={"text-header-bold-h8 md:text-header-bold-h5 text-shade-black self-start md:self-auto"}>
                  {__("OnboardingPage.BlokOwnerSkills.Title")}
               </h1>
               <p className={"md:text-center text-body-regular-b5 text-shade-black text-start"}>
                  {__("OnboardingPage.BlokOwnerSkills.Description")}
               </p>
            </div>
            <div className={"w-full"}>
               <SelectBox
                  canCreatable
                  canSearchable
                  canBeMultiple
                  hasSearchIcon
                  isListVirtualized
                  name={'skills'}
                  classes={'w-full'}
                  inputName={'skills'}
                  data={!!skills.length && skills}
                  error={blokStore.state.errors}
                  onChange={handleOnChangeSkills}
                  value={blokStore.getSelectBoxValues('skills')}
                  placeholder={__("CreateBlokStepForm.ChooseSkill")}
               />
            </div>
         </div>
         <OnboardingBottom
            onClick={handleContinueClick}
            disabled={!blokStore.state?.form.skills?.length}
         />
      </div>
   )
}

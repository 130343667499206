import LocalStorage from "@/js/helpers/localStorage";
import OnboardingSelectAccountType from "../components/OnboardingSelectAccountType";
import OnboardingTalentInterest from "../components/talent/OnboardingTalentInterest";
import OnboardingTalentLocation from "../components/talent/OnboardingTalentLocation";
import OnboardingTalentEducation from "../components/talent/OnboardingTalentEducation";
import OnboardingTalentTitle from "../components/talent/OnboardingTalentTitle";
import OnboardingTalentSkills from "../components/talent/OnboardingTalentSkills";
import OnboardingCompanyInterest from "../components/company/OnboardingCompanyInterest";
import OnboardingCompanyName from "../components/company/OnboardingCompanyName";
import OnboardingTalentExperience from "../components/talent/OnboardingTalentExperience";
import OnboardingTalentBreath from "../components/talent/OnboardingTalentBreath";
import OnboardingTalentWorkPreferences from "../components/talent/OnboardingTalentWorkPreferences";
import OnboardingTalentSalaryExpectation from "../components/talent/OnboardingTalentSalaryExpectation";
import OnboardingTalentLastStep from "../components/talent/OnboardingTalentLastStep";
import OnboardingCompanyIndustry from "../components/company/OnboardingCompanyIndustry";
import OnboardingCompanyInfo from "../components/company/OnboardingCompanyInfo";
import OnboardingCompanySize from "../components/company/OnboardingCompanySize";
import OnboardingCompanyWorkType from "../components/company/OnboardingCompanyWorkType";
import OnboardingCompanyPosition from "../components/company/OnboardingCompanyPosition";
import OnboardingCompanyLastStep from "../components/company/OnboardingCompanyLastStep";
import OnboardingCompanyLocation from "../components/company/OnboardingCompanyLocation";
import OnboardingBlokOwnerInterests from "../components/blokOwner/OnboardingBlokOwnerInterests";
import OnboardingBlokOwnerSkills from "../components/blokOwner/OnboardingBlokOwnerSkills";
import OnboardingBlokOwnerNetworkReach from "../components/blokOwner/OnboardingBlokOwnerNetworkReach";
import OnboardingBlokOwnerLocation from "../components/blokOwner/OnboardingBlokOwnerLocation";
import OnboardingBlokOwnerBreath from "../components/blokOwner/OnboardingBlokOwnerBreath";
import OnboardingBlokOwnerAbout from "../components/blokOwner/OnboardingBlokOwnerAbout";
import OnboardingBlokOwnerServices from "../components/blokOwner/OnboardingBlokOwnerServices";
import OnboardingBlokOwnerDetails from "../components/blokOwner/OnboardingBlokOwnerDetails";
import OnboardingBlokOwnerContact from "../components/blokOwner/OnboardingBlokOwnerContact";
import OnboardingBlokOwnerLastStep from "../components/blokOwner/OnboardingBlokOwnerLastStep";
import EventOnboardingPage from "../../events/components/onboarding/EventOnboardingPage";

export enum OnboardingSteps {
	CompanyIndustry = 'companyIndustry',
	CompanyInfo = 'companyInfo',
	CompanySize = 'companySize',
	SelectAccountType = 'selectAccountType',
	TalentInterest = 'talentInterest',
	TalentLocation = 'talentLocation',
	TalentEducation = 'talentEducation',
	TalentTitle = 'talentTitle',
	TalentSkills = 'talentSkills',
	CompanyInterest = 'companyInterest',
	CompanyName = 'companyName',
	TalentExperience = 'talentExperience',
	TalentBreath = 'talentBreath',
	TalentWorkPreferences = 'talentWorkPreferences',
	TalentSalaryExpectation = 'talentSalaryExpectation',
	TalentLastStep = 'talentLastStep',
	CompanyWorkType = 'companyWorkType',
	CompanyPosition = 'companyPosition',
	BlokOwnerInterests = 'blokOwnerInterests',
	BlokOwnerSkills = 'blokOwnerSkills',
	BlokOwnerNetworkReach = 'blokOwnerNetworkReach',
	BlokOwnerLocation = 'blokOwnerLocation',
	BlokOwnerBreath = 'blokOwnerBreath',
	BlokOwnerDetails = 'blokOwnerDetails',
	CompanyLastStep = 'companyLastStep',
	BlokOwnerAbout = 'blokOwnerAbout',
	BlokOwnerServices = 'blokOwnerServices',
	CompanyLocation = 'companyLocation',
	BlokOwnerContact = 'blokOwnerContact',
	BlokOwnerLastStep = 'blokOwnerLastStep',
	EventOnboardingPage = 'eventOnboardingPage',
}

export const eventOnboardingSteps = [
	OnboardingSteps.SelectAccountType,
	OnboardingSteps.EventOnboardingPage,
]

export const companyOnboardingSteps = [
	OnboardingSteps.CompanyInterest,
	OnboardingSteps.CompanyName,
	OnboardingSteps.CompanyIndustry,
	OnboardingSteps.CompanyInfo,
	OnboardingSteps.CompanySize,
	OnboardingSteps.CompanyLocation,
	OnboardingSteps.CompanyWorkType,
	OnboardingSteps.CompanyPosition,
	OnboardingSteps.CompanyLastStep,
]

export const talentOnboardingSteps = [
	//OnboardingSteps.SelectAccountType,
	OnboardingSteps.TalentInterest,
	OnboardingSteps.TalentLocation,
	OnboardingSteps.TalentEducation,
	OnboardingSteps.TalentTitle,
	OnboardingSteps.TalentSkills,
	OnboardingSteps.TalentExperience,
	OnboardingSteps.TalentBreath,
	OnboardingSteps.TalentWorkPreferences,
	OnboardingSteps.TalentSalaryExpectation,
	OnboardingSteps.TalentLastStep,
]

export const blokOwnerOnboardingSteps = [
	//OnboardingSteps.SelectAccountType,
	OnboardingSteps.BlokOwnerInterests,
	OnboardingSteps.BlokOwnerSkills,
	OnboardingSteps.BlokOwnerNetworkReach,
	OnboardingSteps.BlokOwnerLocation,
	OnboardingSteps.BlokOwnerBreath,
	OnboardingSteps.BlokOwnerDetails,
	OnboardingSteps.BlokOwnerAbout,
	OnboardingSteps.BlokOwnerServices,
	OnboardingSteps.BlokOwnerContact,
	OnboardingSteps.BlokOwnerLastStep,
]

export const onboardingSteps = {
	[OnboardingSteps.SelectAccountType]: <OnboardingSelectAccountType />,
	[OnboardingSteps.TalentInterest]: <OnboardingTalentInterest />,
	[OnboardingSteps.TalentLocation]: <OnboardingTalentLocation />,
	[OnboardingSteps.TalentSkills]: <OnboardingTalentSkills />,
	[OnboardingSteps.TalentTitle]: <OnboardingTalentTitle />,
	[OnboardingSteps.TalentEducation]: <OnboardingTalentEducation />,
	[OnboardingSteps.CompanyInterest]: <OnboardingCompanyInterest />,
	[OnboardingSteps.CompanyName]: <OnboardingCompanyName />,
	[OnboardingSteps.CompanyIndustry]: <OnboardingCompanyIndustry />,
	[OnboardingSteps.CompanyInfo]: <OnboardingCompanyInfo />,
	[OnboardingSteps.TalentExperience]: <OnboardingTalentExperience />,
	[OnboardingSteps.TalentBreath]: <OnboardingTalentBreath />,
	[OnboardingSteps.TalentWorkPreferences]: <OnboardingTalentWorkPreferences />,
	[OnboardingSteps.TalentSalaryExpectation]: <OnboardingTalentSalaryExpectation />,
	[OnboardingSteps.TalentLastStep]: <OnboardingTalentLastStep />,
	[OnboardingSteps.CompanySize]: <OnboardingCompanySize />,
	[OnboardingSteps.CompanyWorkType]: <OnboardingCompanyWorkType />,
	[OnboardingSteps.CompanyPosition]: <OnboardingCompanyPosition />,
	[OnboardingSteps.CompanyLastStep]: <OnboardingCompanyLastStep />,
	[OnboardingSteps.CompanyLocation]: <OnboardingCompanyLocation />,
	[OnboardingSteps.BlokOwnerInterests]: <OnboardingBlokOwnerInterests />,
	[OnboardingSteps.BlokOwnerSkills]: <OnboardingBlokOwnerSkills />,
	[OnboardingSteps.BlokOwnerNetworkReach]: <OnboardingBlokOwnerNetworkReach />,
	[OnboardingSteps.BlokOwnerLocation]: <OnboardingBlokOwnerLocation />,
	[OnboardingSteps.BlokOwnerBreath]: <OnboardingBlokOwnerBreath />,
	[OnboardingSteps.BlokOwnerDetails]: <OnboardingBlokOwnerDetails />,
	[OnboardingSteps.BlokOwnerContact]: <OnboardingBlokOwnerContact />,
	[OnboardingSteps.BlokOwnerLastStep]: <OnboardingBlokOwnerLastStep />,
	[OnboardingSteps.BlokOwnerAbout]: <OnboardingBlokOwnerAbout />,
	[OnboardingSteps.BlokOwnerServices]: <OnboardingBlokOwnerServices />,
	[OnboardingSteps.EventOnboardingPage]: <EventOnboardingPage />,
} as const

export interface OnboardingOutletContextType {
	currentStep: JSX.Element;
	handleChangeStep: (isBack?: boolean) => void;
	setCurrentStep: React.Dispatch<React.SetStateAction<JSX.Element>>
	setMailActivationSucces?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const resetOnboardingLocalStorage = () => {
	LocalStorage.remove('onboardingPayload')
	LocalStorage.remove('onboardingSteps')
	LocalStorage.remove('lastOnboardingStep')
	LocalStorage.remove('onboardingSelectedAccountType')
}

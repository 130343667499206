import { PropsWithChildren } from "react";
import { BaseComponentProps } from "@/utils/BaseComponentProps";
import Spinner from "../Spinner/Spinner";

interface IButton extends BaseComponentProps {
	children: React.ReactNode;
	attrs?: React.ButtonHTMLAttributes<HTMLButtonElement>;
	outlined?: Boolean;
	plain?: Boolean;
	plainDisabled?: Boolean;
	plainDanger?: Boolean;
	disabled?: Boolean;
	classes?: string;
	isLoading?: Boolean;
	border?: Boolean;
	minWidth?: Boolean;
}

const defaultButtonClassNames =
	"bg-primary-500 font-semibold text-sm hover:bg-primary-300 text-white py-1 px-4 rounded-full";
const outlineButtonClassNames =
	"outlined bg-white font-semibold text-sm text-primary-500 py-1 px-4 border-2 border-primary-500 rounded-full";
const disabledButtonClassNames =
	"bg-primary-400 text-white font-bold py-1 px-4 rounded h-[36px] cursor-not-allowed rounded-full disabled-button";
const plainButtonClassNames = "text-primary-500 py-1 px-4"
const plainDangerButtonClassNames = "text-red-500 py-1 px-4"
const plainDisabledButtonClassNames = "text-gray-500 cursor-default py-1 px-4"
const borderButtonClassNames = "bg-gray-50 outlined font-semibold py-1 px-4 border border-gray-300 rounded-full";

function Button(props: IButton) {
	const { attrs, children, outlined, plain, plainDanger, disabled = false, isLoading = false, plainDisabled, border, minWidth = true } = props;

	const getClassNames = () => {
		if (disabled) {
			return disabledButtonClassNames;
		} else if (outlined) {
			return outlineButtonClassNames;
		} else if (plain) {
			return plainButtonClassNames;
		} else if (plainDanger) {
			return plainDangerButtonClassNames;
		} else if (isLoading) {
			return disabledButtonClassNames;
		} else if (plainDisabled) {
			return plainDisabledButtonClassNames;
		} else if (border) {
			return borderButtonClassNames;
		}

		return defaultButtonClassNames;
	};

	return (
		<button
			{...attrs}
			disabled={(isLoading || disabled) as boolean}
			className={`${props.classes} ${minWidth && 'min-w-[105px]'} h-[36px] ${getClassNames()} transition whitespace-nowrap inline-flex !items-center justify-center`}
		>
			<div className={"flex justify-center items-center"}>
				{isLoading ? <div className={"scale-125"}><Spinner /></div> : children}
			</div>
		</button >
	);
}

const Icon = (props: PropsWithChildren) => {
	return <>{props.children}</>;
};

Button.Icon = Icon;

export default Button;

import BaseService from "@/js/core/BaseService";
import { PackageEndpoints } from '@/js/modules/packages/store/PackageEndpoints';

export default class PackageService extends BaseService {
  async fetchPackage(id: string) {
    return this.api().get(PackageEndpoints.FetchPackage.replace(':id', id))
  }
  async fetchForForm(id: string) {
    return await this.api().get(PackageEndpoints.ById.replace(':id', id));
  }
  async createService(payload: unknown) {
    return await this.api().post(PackageEndpoints.Create, payload);
  }
  async updateService(id: string, payload: unknown) {
    return await this.api().put(PackageEndpoints.ById.replace(':id', id), payload);
  }
  async fetchAllSponsorships(pageParam: number, query?: string) {
    return this.api().get(`${PackageEndpoints.FetchAllSponsorships}?page=${pageParam.toString()}${query ? `&search=${query.toString()}` : ''}`)
  }
}

import BaseStore from "@/js/core/BaseStore";
import CommentService from "../services/CommentService";

interface ICommentStore { }
const commentStore = {
	numberOfComments: '',
	commentList: [],
	isOwner: Boolean,
	isUserInBlok: Boolean
}

class CommentStore extends BaseStore<ICommentStore, CommentService> {
	initializeStore(state?) {
		return this.useStore(state);
	}

	async createComment(payload) {
		return await this.service.createComment(payload);
	}

	async updateComment(ownerId, payload) {
		return await this.service.updateComment(ownerId, payload);
	}

	async deleteComment(ownerId, id) {
		return await this.service.deleteComment(ownerId, id);
	}

	async fetchAllCommentBy(ownerId) {
		const result = await this.service.fetchAllCommentBy(ownerId);
		return result;
	}

	async fetchAllCommentByForumId(ownerId) {
		const result = await this.service.fetchAllCommentByForumId(ownerId);
		return result;
	}

	setIsUserInBlok(isUserInBlok: Boolean) {
		this.state.isUserInBlok = isUserInBlok
	}

	setIsOwner(isOwner: Boolean) {
		this.state.isOwner = isOwner
	}

	setCommentList(commentList: []) {
		this.state.commentList = commentList
		this.setCommentCount(commentList?.length)
	}

	setCommentCount(numberOfComments: any) {
		this.state.numberOfComments = numberOfComments
	}

	calculateTotalComments(commentList: []): number {
		let totalCount = 0;
	
		const traverseComments = (comments: any[]) => {
			for (const comment of comments) {
				totalCount++;
				if (comment.sub_comments?.length) {
					traverseComments(comment.sub_comments);
				}
			}
		};
	
		traverseComments(commentList);
	
		return totalCount;
	}

	setCommentListWithSubcomments(commentList: []) {
		this.state.commentList = commentList;
		const totalComments = this.calculateTotalComments(commentList);
		this.setCommentCount(totalComments);
	}

}

export default new CommentStore(commentStore, CommentService) as CommentStore;
